import React, {useEffect, useState} from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Form,
  Button,
  DropdownButton,
  Dropdown, Nav, NavDropdown, Navbar
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";

// IMAGES
// import siteLogo from "assets/sitelogo.png";
import siteLogo from "assets/DatLandlogo.gif";

import userImage from "assets/userimage.png";
import UpdateCart from "pages/UpdateCart";
import SearchProducts from "components/SearchProducts";
import LoginPage from "pages/Login";



function Logout(){
  localStorage.clear();
  navigator(<LoginPage/>)
}

function SearchForm() {
  const navigator = useNavigate();
  return (
    <>
      {/* <InputGroup className="">
        <Form.Control
          placeholder="Search entire store here..."
          aria-label="Search entire store here..."
          aria-describedby="search-bar"
        />
        <Button variant="outline-secondary" id="search-bar">
          <FontAwesomeIcon icon={solid("magnifying-glass")} />
        </Button>
      </InputGroup> */}
      <SearchProducts/>
    </>
  );
}

export default function Header() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className="top-bar">
        <Container>
          <Row>
            <Col md={4}>
              <div className="left-side">
                <ul>
                  <li>
                    <FontAwesomeIcon icon={regular("clock")} />
                  </li>
                  <li>Mon-Thu: 9:00 AM - 5:30 PM</li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="mid-area">
                <p>Wellcome To Datland Tech</p>
              </div>
            </Col>
            <Col md={4}>
              <div className="right-side">
                <ul>
                  <li>Social Media : </li>
                  <li>
                    <a href="#" target="_blank">
                      <FontAwesomeIcon icon={brands("square-facebook")} />
                    </a>
                  </li>
                  <li>
                    <a href="#" target="_blank">
                      <FontAwesomeIcon icon={brands("square-instagram")} />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mid-bar">
        <Container>
          <Row>
            <Col md={3}>
              <Link to="/" className="logo">
                <img src={siteLogo} alt="image" />
              </Link>
            </Col>
            <Col md={9}>
              <div className="right-side">
                <div className="h-searchbox">
                  <SearchForm />
                </div>
                <div className="h-callinfo">
                  <ul>
                    <li className="icon-area">
                      <span>
                        <FontAwesomeIcon icon={solid("phone")} />
                      </span>
                    </li>
                    <li>
                      <span>Call Us For More Info </span>
                      <a href="tel:7142489797">(714) 248-9797</a>
                    </li>
                  </ul>
                </div>
                <div className="h-cart">
                  <Link to="/cart">Cart
                    <FontAwesomeIcon icon={solid("cart-shopping")} />
                    <span className="c-items">
                      {
                        // useEffect(()=>
                          localStorage.getItem('cart') != null? JSON.parse(localStorage.getItem('cart')).length :0
                        // ,[])
                        
                      }
                      </span>
                  </Link>
                </div>
                {
                localStorage.getItem('user-info') && !localStorage.getItem('user-info').error? 
                <>
                <div className="h-wishlist">
                  <ul>
                    {/* <li className="icon-area">
                      <span>
                        <FontAwesomeIcon icon={regular("heart")} />
                      </span>
                    </li>
                    <li>
                      <Link to="/">Wishlist</Link>
                    </li> */}
                  </ul>
                </div>
                
                <div className="h-user d-none">
                  <DropdownButton
                    id="dropdown-user"
                    title={
                      <span>
                        <img src={userImage} alt="image" />
                      </span>
                    }
                  >
                    <Dropdown.Item href="#">Profile</Dropdown.Item>
                    <Dropdown.Item href="#">Settings</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
                  </DropdownButton>
                </div>
                <Link to="/user-dashboard">
                <FontAwesomeIcon icon={solid('user')} />
                    <span className="c-items p-1  ">
                  Dashboard
                  </span>
                </Link>
                <Link onClick={Logout}>
                <FontAwesomeIcon icon={solid('right-from-bracket')} />
                    <span className="c-items p-1  ">
                  Logout
                  </span>
                </Link>
                </>
                 : 
                 <>
                 <Link to="/login">
                 <FontAwesomeIcon icon={solid('user')} /><span className="p-1">Login</span></Link>
                 <Link to="/register">
                 <FontAwesomeIcon icon={solid('user-plus')} /><span className="p-1">Register</span></Link>
                 </>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="nav-bar">
        <Container>
          <Row>
            <Col md={3} className="left-menu-drop">
              <DropdownButton
                id="dropdown-shopcategory"
                title="Shop By Categories"
              >
                {/* <Dropdown.Item as={Link} to="/products">
                Optical Disk
                </Dropdown.Item> */}
                <Dropdown.Item as={Link} to="/products">
                Tape Drives
                </Dropdown.Item>   
                <Dropdown.Item as={Link} to="/products">
                Tape Media
                </Dropdown.Item>   
                {/* <Dropdown.Item as={Link} to="/products">
                Optical Drive
                </Dropdown.Item>    */}
                <Dropdown.Item as={Link} to="/products">
                Parts & Accessories
                </Dropdown.Item>                
                
              </DropdownButton>
            </Col>
            <Col md={9}>
              <Navbar expand="lg" className="bg-body-tertiary">
                  <Navbar.Toggle aria-controls="main-navbar-nav" />
                  <Navbar.Collapse id="main-navbar-nav">
                    <Nav className="me-auto">
                      <NavLink exact='true' as={Link} to={'/'} onClick={setExpand}>Home</NavLink>
                      <NavLink exact='true' as={Link} to={'/about'} onClick={setExpand}>About Us</NavLink>
                      <NavLink exact='true' as={Link} to={'/contact-us'} onClick={setExpand}>Contact Us</NavLink>
                      <NavLink exact='true' as={Link} to={'/repair-services'} onClick={setExpand}>Repairs Services</NavLink>
                      <NavLink exact='true' as={Link} to={'/products'} onClick={setExpand}>Products</NavLink>
                      <NavLink exact='true' as={Link} to={'/testimonials'} onClick={setExpand}>TESTIMONIALS</NavLink>
                      {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">
                          Action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                          Another action
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">
                          Something
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">
                          Separated link
                        </NavDropdown.Item>
                      </NavDropdown> */}
                    </Nav>
                    <div className="nav-order">
                      <Link to='/products'>Order Product <FontAwesomeIcon icon={solid("arrow-right")} /></Link>
                    </div>
                  </Navbar.Collapse>
               
              </Navbar>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
