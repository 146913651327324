import React,{useState,useEffect} from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Home from 'pages/Home'

export default function Addproducts(){
    const navigator = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator(<Home/>)
            console.log('user is null');
        }else if( JSON.parse(localStorage.getItem('user-info')).role == 1 ){
            console.log('user is not null but admin ');
            navigator(<Home/>)
        }
    },[])

    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [price,setPrice] = useState('');
    const [image,setImage] = useState('');

    const formdata = new FormData();

    async function storeProduct() {
        formdata.append('name', name);
        formdata.append('description', description);
        formdata.append('price', price);

        if (image) {
            formdata.append('image', image);
        }

        let result = await fetch(process.env.REACT_APP_BASE_URL + 'api/store-products', {
            method: 'POST',
            body: formdata
        });

        result = await result.json();
        console.log('product uploading result:', JSON.stringify(result));

        if (result.data) {
            Swal.fire('Success', 'Product Added Successfully', 'success');
            navigator('/admin/all-products');
        } else {
            // Handle the case when the server responds with an error
            Swal.fire('Error', 'Failed to add product', 'error');
        }
    }

    
    return(
        <>
        
        <div className='col-sm-6 formDesign'>
        <h1>Add Products</h1>
        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control' placeholder='Name' /><br/>
        <input type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} className='form-control' placeholder='Description' /><br/>
        <input type="number" value={price} onChange={(e)=>{setPrice(e.target.value)}} className='form-control' placeholder='Price' /><br/>
        <input type="file" onChange={(e)=>{setImage(e.target.files[0])}} className='form-control' placeholder='Image' /><br/>
        <button onClick={storeProduct} className='btn btn-primary'>Add</button>
        </div>

        </>
    );
}

