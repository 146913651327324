import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import "./App.css";
import Header from "components/Header";
import Footer from "components/Footer";
import About from "pages/About";
import Wishlist from "pages/Wishlist";
import Contact from "pages/Contact";
import RepairServices from "pages/Repair-Services";
import Products from "pages/Products";
import Testimonials from "pages/Testimonials";
import ProductDetail from "pages/Product-Detail";
import Cart from "pages/Cart";
import Checkout from "pages/Checkout";
import RefundPolicy from "pages/Refund-Policy";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsAndConditions from "pages/Terms-and-Conditions";
import LoginPage from "pages/Login";
import RegisterPage from "pages/Register";
import Userdashboard from "pages/Userdashboard";
import Userorders from "pages/Userorders";
import Userinvoice from "pages/Userinvoice";
import Manageprodfile from "pages/Manageprodfile";
// import StripePaymentComponent from "pages/StripePaymentComponent";








// for admin dashboard 
import Protected from "admin/Protected";
import Login from "admin/pages/Login";
import Allproducts from "admin/pages/Allproducts";
import Addproducts from "admin/pages/Addproducts";
import Alltestimonials from "admin/pages/Alltestimonials";
import Allorders from "admin/pages/Allorders";
import Dashboard from "admin/pages/Dashboard";
import Invoice from "admin/pages/Invoice";
import Updateproduct from "admin/pages/Updateproduct";
import Websitesettings from "admin/pages/Websitesettings";
import Allusers from "admin/pages/Allusers";
import Updateuser from "admin/pages/Updateuser";
import Createuser from "admin/pages/Createuser";



import Dashboardheader from "admin/components/Dashboardheader";
import Dashboardfooter from "admin/components/Dashboardfooter";

import backendApp from './backendApp';
import Stripe from "pages/Stripe";
import Addtestimonials from "admin/pages/Addtestimonials";
import Updatetestimonial from "admin/pages/Updatetestimonial";



function App() {

  return (
    !window.location.pathname.includes('/admin') ? (
      <BrowserRouter>
        <header className="pageheader">
          <Header />
        </header>
        <Routes>
               
          <Route path="/login" element={<LoginPage />} />        
          <Route path="/register" element={<RegisterPage />} />        
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/repair-services" element={<RepairServices />} />
          <Route path="/products" element={<Products />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/product-detail/:id" element={<ProductDetail/>} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/user-dashboard" element={<Userdashboard />} />
          <Route path="/user-orders" element={<Userorders />} />
          <Route path='/user/invoice-show/:id' element={ <Protected comp={Userinvoice}/> }></Route>
          <Route path="/manage-profile" element={<Manageprodfile />} />
          {/* <Route path="/show-stripe-form" element={<StripePaymentComponent />} /> */}
          
          
          <Route path="/" element={<Home />} />   
          {/* admin route start */}
          {/* <Route path='/admin/login' element={ <Protected comp={Login}/> }></Route> */}
          {/* admin route end*/}
        </Routes>
        <footer>
          <Footer />
        </footer>
      </BrowserRouter> 
    ) : (

    <BrowserRouter> 
    <Dashboardheader/> 
    {(JSON.parse(localStorage.getItem('user-info')) && JSON.parse(localStorage.getItem('user-info')).error !== '' )? '' : ''}
      <Routes>
      <Route path='/admin/login' element={ <Protected comp={Login}/>}></Route>
      <Route path='/admin/all-products' element={ <Protected comp={Allproducts} /> }></Route>
      <Route path='admin/all-testimonials' element={ <Protected comp={Alltestimonials} /> }></Route>
      <Route path='/admin/add-product' element={ <Protected comp={Addproducts}/> }></Route>
      <Route path='/admin/add-testimonial' element={ <Protected comp={Addtestimonials}/> }></Route>
      <Route path='/admin/update-testimonial/:id' element={ <Protected comp={Updatetestimonial}/> }></Route>
      <Route path='/admin/update-product/:id' element={ <Protected comp={Updateproduct}/> }></Route>
      <Route path='/admin/all-orders' element={ <Protected comp={Allorders}/> }></Route>
      <Route path='/admin/dashboard' element={ <Protected comp={Dashboard}/> }></Route>
      <Route path='/admin/invoice-show/:id' element={ <Protected comp={Invoice}/> }></Route>
      <Route path='/admin/website-settings' element={ <Protected comp={Websitesettings}/> }></Route>
      <Route path='/admin/all-users' element={ <Protected comp={Allusers} /> }></Route>
      <Route path='/admin/createuser' element={ <Protected comp={Createuser} /> }></Route>
      <Route path='/admin/update-user/:id' element={ <Protected comp={Updateuser} /> }></Route>
    
      <Route path='/' element={ <Protected comp={Home} /> }></Route>
      </Routes>
    <Dashboardfooter/>
    </BrowserRouter> 
    

    )
  );
}

export default App;
