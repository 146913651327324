import React, { useState,useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import productsData from 'admin/pages/Allproducts';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import Pagination from 'components/Pagination'



import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Form,
  InputGroup,
  Sticky,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

// images
import featuredImage from "assets/cd.png";
import featuredImageone from "assets/hard.png";
import featuredImagetwo from "assets/gb.png";

export default function PriceFilterSection() {

  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  }

  
// for fetching products data dynamically
const [productsData, setProductsData] = useState([]);

useEffect(() => {
  (async () => {
    try {
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
      result = await result.json();
      setProductsData(result);
      console.log("products loaded successfully");
    } catch (error) {
      console.error(error);
    }
  })();
}, []); // Empty dependency array to run the effect only once


// initializing the datatable here
// useEffect(() => {
//   $('#myDataTable').DataTable();
// }, []);




  const uniqueCategories = new Set();

  productsData.forEach(element=> {
      let category = (element.category_details != null)? ( ( (element.category_details).split(",")[0] ).replace('Category Name: ','') ).trim() : '';
      uniqueCategories.add(category) ;
      }
    )
    const [selectedCategory, setSelectedCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const handleChange = (event) => {
      const category = event.target.value;
      setSelectedCategory(category);
      setCurrentPage(1);
      if (event.target.checked) {
        console.log('Checked and value is: ' + category);
      } else {
        console.log('Unchecked and value is: ' + category);
      }
    };
    let filteredProducts;

    if(selectedCategory == 'allproducts'){
      filteredProducts = productsData;
    }else{
          filteredProducts = selectedCategory

        ? productsData.filter((item) => {
            const category = item.category_details
              ? item.category_details
                  .split(',')[0]
                  .replace('Category Name: ', '')
                  .trim()
              : '';
            return category === selectedCategory;
          })
        : productsData;
    }

    // paginatio code start here
  const products = (filteredProducts=='')?productsData:filteredProducts; // Your list of products  
  const itemsPerPage = 9; // Number of items to display per page
  
  // Calculate the range of products to display based on currentPage
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
   const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);
  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // paginatio code end here




  return (

    
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Products</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Products</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4 productpageMain">
        <div className="product__Section">
          <Container>
            <Row>
            <Col md={3}>
            <>
            <div className="sticky-content">
                          <div className="inner__products">
                            {/* <div className="sort__section">
                              <span>Sort by:</span>
                              <Form>
                                <Form.Select aria-label="Default select example">
                                  <option>Select</option>
                                  <option value="1">By Name</option>
                                  <option value="2">By Date</option>
                                  <option value="3">Ascending</option>
                                  <option value="3">Descending</option>
                                </Form.Select>
                              </Form>
                            </div> */}

                            

                            {/* <div className="price__filter__section">
                              <span>Price</span>
                              <div className="inner__price">
                                <Row>
                                  <Col md="5">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="0"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>
                                  </Col>
                                  <Col md="2">to</Col>
                                  <Col md="5">
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        type="number"
                                        placeholder="250"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                      />
                                    </InputGroup>

                                    <div className="filter__price"></div>
                                  </Col>
                                  <div>
                                    <Form.Range className="custom-range" />
                                  </div>
                                </Row>
                              </div>
                            </div> */}

<span className="sec-heading">Category Type</span>
                            <div className="product__cat__section">
                              
                              {/* <Form> */}
                                {/* {["checkbox"].map((type) => ( */}
                                  
                                  <div key={`default-checkbox`} className="mb-3">
                                  {
                                  Array.from(uniqueCategories).map((item,index)=>(  
                                      (item != '' && item != 'Optical Disk' && item != 'Optical Drive')?
                                      <Form.Check // pretter-ignore
                                        type='radio'
                                        id={`default-check-${index}`}
                                        label={ item }
                                        name='categoryName'
                                        value={item}
                                        onChange={handleChange}
                                      />
                                      : ''
                                ))
                                }
                                  </div>
                              {/* </Form> */}
                            </div>
                                
                            <div className="availability__section">
                              {/* <span>Availability</span> */}
                              {/* <Form> */}
                                {["checkbox"].map((type) => (
                                  <div key={`default-${type}`} >
                                    <Form.Check // prettier-ignore
                                      type='radio'
                                      id={`default-${type}`}
                                      label={`Show All (${productsData.length})`}
                                      value="allproducts"
                                      name='categoryName'
                                      onChange={handleChange}
                                    />

                                    {/* <Form.Check
                                      disabled
                                      type='radio'
                                      name='categoryName'
                                      label="Out of stock (0)"
                                      id={``}
                                    /> */}
                                  </div>
                                ))}
                              {/* </Form> */}
                            </div>
                          </div>
                        </div>
            </>
              </Col>
              <Col md={9}>
              <Row>               
                  {
                    currentProducts.map((item,index)=>
                    <>
                    <Col md={4}>
                    <div className="total__product__sec">
                      <div className="product__image feaut-img">
                        <Link to={"/product-detail/"+item.id} onClick={setExpand}>
                          {(item.images != '')?  
                              <>
                              <FontAwesomeIcon icon={solid("magnifying-glass")}  />
                              <img src={  (((item.images).split(",")[1]).includes('|'))? ( ( ( (item.images).split(",")[1] ).split('|')[0] ).replace(" Product Image URL: ",'') ).trim()  : (((item.images).split(",")[1]).replace(' Product Image URL: ','')).trim() }/>
                               </> 
                           : ''
                          }
                        </Link>
                      </div>
                      {/* <div className="feaut__text">
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                        </ul>
                        <div className="feaut__reviews">
                          <span>Reviews (4)</span>
                        </div>
                      </div> */}
                      <div className="slide__bottom__text pt-3">
                        <strong>
                          <Link to={"/product-detail/"+item.id} onClick={setExpand}>{item.name}</Link>
                        </strong>
                        {/* <p>
                          {(item.description).substr(1, 200)}
                        </p> */}
                        <span>
                        <Link to={"/product-detail/"+item.id} onClick={setExpand}>
                          ${item.calculated_price}
                          {/* <del>$400 </del> */}
                          {" "}
                          </Link>
                        </span>
                        
                        <span className="stock-color">
                           {(item.product_availability == 'Instock')? 
                           <>
                           <FontAwesomeIcon icon={solid("circle-check")}/>
                           Instock
                           </>
                           : item.product_availability
                           }
                        </span>
                      </div>
                    </div>
                    </Col>
                    </>
                    )
                  }
 
              </Row>
            </Col>

              
            </Row>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={(filteredProducts=='')? productsData.length :filteredProducts.length}
              onPageChange={handlePageChange}
          />

          </Container>
        </div>
      </div>
    </>
  );
}
