import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'; 
import Swal from 'sweetalert2';

function Updateuser(props){
    const { id } = useParams(); 

    const [name,setName] = useState('');
    // const [description,setDescription] = useState('');
    const [email,setEmail] = useState('');
    // const [image,setImage] = useState('');


    // fetching user data
    useEffect(()=>{
        ( async()=>{
            let result = await fetch(process.env.REACT_APP_BASE_URL+'api/user-details/'+id)
            result = await result.json();
            if(result.status == '200'){
                setName(result.data.name)
                // setDescription(result.data.description)
                setEmail (result.data.email)
                // setImage(result.data.image_path)
            }else{
                console.warn('data not found')
            }   
            })()
    },[])


    const formdata = new  FormData()
    async function update(){
        formdata.append('name',name);
        // formdata.append('description',description);
        formdata.append('email',email);
        // if(image != ''){
        //     formdata.append('image',image);
        // }
        
        
       let result = await fetch(process.env.REACT_APP_BASE_URL+'api/update-user/'+id,{
                    method:'POST',
                    body: formdata
                    })

            result = await result.json();
            Swal.fire('user Updated Successfully')
    }
    return(
        <>

        
        <div className='col-sm-6 formDesign'>
        <h1 className='my-5'>Update user</h1>

        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control' placeholder='Name' /><br/>
        {/* <input type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} className='form-control' placeholder='Description' /><br/> */}
        <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className='form-control' placeholder='Email' /><br/>
         {/* <input type="file" onChange={(e)=>{setImage(e.target.files[0])}} className='form-control' placeholder='Image' /><br/> */}
        <button onClick={update} className='btn btn-primary'>Update</button>
        </div>

        </>
    );
}

export default  Updateuser