import React from 'react'
import 'boxicons';
// import "../../index.css";


export default function Footer(){
 
    return(
    <>
    <footer className='adminpanelFooter'>
        <p>Datland Tech Admin Panel</p>
    </footer>
    </>
    );
}

