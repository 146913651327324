import React from "react";
import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import aboutImage from "assets/about-img.png";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function About() {
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">About Us</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">About</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container ">
        
          <div className="about-section top__about">
            <Container>
              <Row className="align-items-center inner__about">
                <Col md={5}>
                  <div className="about-img">
                    <img src={aboutImage} alt="" />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="about-text">
                    <p>
                    Datland Technologies, Inc. is known as a market leader in the sales, repairs, and technical support of tape back-up drives. With our wide-ranging product knowledge and years of expertise, we specialize in all varieties, including 4mm, 8mm, Travan, AIT, DLT, SDLT, LTO, Libraries, and Autoloaders. We have a host of trained technicians who ensure that your tape drive reaches its promised lifespan and continues to serve you for long-term data storage. Our knowledgeable technical support team offers free troubleshooting services around the clock to help you diagnose and correct problems with your tape back-up drive anywhere, anytime. Our customers keep coming back for unparalleled quality back-up and repair solutions: we maintain a large clientele worldwide. Call us today to upgrade 
                    or repair your tape back-up drive and we will be happy to help you.
                    </p>

                    <p>
                    We pride ourselves on being a progressive company that stays at the forefront of the industry. We stock efficient back-up solutions from all the top brands, ensuring that our customers have access to the latest and most reliable technology. Whether you are in persistent need of a new drive or looking for a cost-effective repair solution, we have you covered. Not only do we offer new and refurbished tape back-up drives, but we also buy (as-is) drives, providing you with an opportunity to save significantly. Our commitment to offering high-quality and budget-friendly options sets us apart, enabling our customers to find the right solution at the best value.
                    </p>

                    
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        
      </div>
    </>
  );
}
