import React, { useEffect, useState } from "react";
// import Header from "./Header";
import { Link,useNavigate } from 'react-router-dom';
import { Container, Table } from "react-bootstrap";
import Swal from "sweetalert2";
import Login from "admin/pages/Login";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';

export default function Products(){
  const [productsData, setProductsData] = useState([]);
  const navigator = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator(<Login/>)
        }else if( JSON.parse(localStorage.getItem('user-info')).role != 1 ){
          navigator(<Login/>)
        }
    },[])

  useEffect(() => {
    (async () => {
      try {
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
        result = await result.json();
        setProductsData(result);
        console.log("data loaded successfully");
      } catch (error) {
        console.error(error);
      }
    })();
  }, []); // Empty dependency array to run the effect only once

  // initializing the datatable here
  useEffect(() => {
    $('#myDataTable').DataTable();
  }, []);


  function deleteProd(id){
    (async()=>{
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/delete-product/'+id,{
        method: 'delete'
      });
      result = await result.json();
      if(result.status == '200'){
        (async () => {
            try {
              let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
              result = await result.json();
              setProductsData(result);
              console.log("data loaded successfully,after delete");
            } catch (error) {
              console.error(error);
            }
          })();
        
        (async()=>{
          await Swal.fire("Product Deleted Successfully"); 
          // window.location.reload()
        })()  

    }else{
      Swal.fire({
        icon:'error',
        title:'Failed',
        text:"Product Couldn't Deleted !!"
      });
    }
    })()
}





    return (
        <>
        {/* <Header/> */}
        <Container>
        <div className="d-flex  justify-content-between mt-4 mb-4">
        <h2>All Products</h2>
        <Link to="/admin/add-product"><button className="btn btn-primary">Add Product</button></Link>
        </div>
        
        <Table className="col-md-8 admin-all-products" id="myDataTable" striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Description</th>
          <th>Price</th>
          <th>Image</th>
          <th>Delete</th> 
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
     {
     productsData.map((item,index)=>
        <tr>
          <td>{index+1}</td>
          <td>{item.name}</td>
          <td>{item.description}</td>
          <td>${item.calculated_price}</td>
          <td>
            <img src={  ((''+(item.images).split(",")[1]+'').includes('|'))? ( ( ( (item.images).split(",")[1] ).split('|')[0] ).replace(" Product Image URL: ",'') ).trim()  : ((''+(item.images).split(",")[1]+'').replace(' Product Image URL: ','')).trim() }/>
         </td>
         <td><button onClick={()=>{deleteProd(item.id) }} className="btn btn-danger">Delete</button></td>
         <td>
            <Link to={'/admin/update-product/'+item.id}>
                <span className="btn btn-primary">Edit</span>
            </Link>
        </td>
        </tr>
        )
    }
        
      </tbody>
        </Table>
        
    </Container>

        </>
    );
}

