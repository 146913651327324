import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import menImage from "assets/men.png";
import menImageone from "assets/men1.png";
import menImagetwo from "assets/men3.png";

export default function Testimonials() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  (async () => {
             try {
               let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-testimonials');
               result = await result.json();
               setTestimonialsData(result);
               console.log("data loaded successfully,after delete");
             } catch (error) {
               console.error(error);
             }
           })();
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Testimonials</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Testimonials</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4">
        <div className="testimonials__section">
          <Container>
            <Row>
              {
            testimonialsData.map((item,index)=>
                <Col md={4}>
                <div className="skew__reviews">
                  <div className="top-reviews">
                    <div className="main__reviews">
                      <img src={item.image_path} alt="" />
                    </div>
                    <div className="box__back">
                      <div className="reviews__stars">
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                          <li>
                            <FontAwesomeIcon icon={solid("star")} />
                          </li>
                        </ul>
                      </div>
                      <div className="reviews__text">
                        <p>
                        {item.comment}
                        </p>
                        <strong>{item.name}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                </Col>
            )
              } 
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
