import React,{useEffect,useState} from "react"
import { Link,useNavigate } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { wait } from "@testing-library/user-event/dist/utils";


export default function Dashboard(){
const navigator = useNavigate();
const [ordersData, setOrdersData] = useState([]);
const [productsData, setProductsData] = useState([]);
const [UsersData, setUsersData] = useState([]);
const [allOrderItems,setAllOrderItems] = useState([]);

    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator('/')
        }else if( JSON.parse(localStorage.getItem('user-info')).role != 1 ){
            navigator('/')
        }
    },[])


  //   feth all orders
  useEffect(() => {
    (async () => {
      try {
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-orders');
        result = await result.json();
        setOrdersData(result);
        console.log("orders data loaded successfully");
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);


  //feth all products
    useEffect(() => {
      (async () => {
        try {
          let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
          result = await result.json();
          setProductsData(result);
          console.log("data loaded successfully");
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);

  
  // fetch all users
    useEffect(() => {
      (async () => {
        try {
          let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-users');
          result = await result.json();
          setUsersData(result);
          console.log("data loaded successfully");
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);


    let orderId=0;
    let total = 0;    
    
    // fetching order data and updating allOrderItems
    // setTimeout(()=>{
    //   ( async()=>{
    //         let result = await fetch(process.env.REACT_APP_BASE_URL+"api/orderitems/show")
    //         result = await result.json();
    //         if(result.status == '200'){
    //             setAllOrderItems(result.data)
    //         }else{
    //             console.warn('data not found')
    //         }   
    //         })()  
    //     },1000)  
    
        // calculating totals
        // setTimeout(()=>{
        //   if(allOrderItems != ''){
        //     allOrderItems.forEach((item) => {
        //       total += parseInt(item.product_price*item.product_qty);
        //     });
        //   }else{
        //      console.log('data orders items are empty') 
        //   } 
        // },2000)  


    return (
        <>
        
        <div className="home-content">
        <h2 className="">Dashboard</h2>
        <div class="overview-boxes">
          
          <div class="box">
          
            <div class="right-side">
              <div class="box-topic">Total Order</div>
              <div class="number">{ordersData.length}</div>
              {/* <div class="indicator">
                <i class="bx bx-up-arrow-alt"></i>
                <span class="text">Up from yesterday</span>
              </div> */}
            </div>
            <i class="bx bx-cart-alt cart"></i>
          </div>
          <div class="box">
            <div class="right-side">
              <div class="box-topic">Total Sales</div>
              <div class="number">{total}</div>
              {/* <div class="indicator">
                <i class="bx bx-up-arrow-alt"></i>
                <span class="text">Up from yesterday</span>
              </div> */}
            </div>
            <i class="bx bxs-cart-add cart two"></i>
          </div>
          <div class="box">
            <div class="right-side">
              <div class="box-topic">Total User</div>
              <div class="number">{UsersData.length}</div>
              {/* <div class="indicator">
                <i class="bx bx-up-arrow-alt"></i>
                <span class="text">Up from yesterday</span>
              </div> */}
            </div>
            <i class="bx bx-cart cart three"></i>
          </div>
          <div class="box">
            <div class="right-side">
              <div class="box-topic">Total Products</div>
              <div class="number">{productsData.length}</div>
              {/* <div class="indicator">
                <i class="bx bx-down-arrow-alt down"></i>
                <span class="text">Down From Today</span>
              </div> */}
            </div>
            <i class="bx bxs-cart-download cart four"></i>
          </div>
        </div>
      
        <div class="sales-boxes">
          <div class="recent-sales box">
            <div class="title">Recent Sales</div>
            
              {/* <ul class="details">
                <li class="topic">Date</li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
                <li><a href="#">02 Jan 2021</a></li>
              </ul> */}
              {/* <ul class="details">
                <li class="topic">Customer</li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
                <li><a href="#">lorem ipsum</a></li>
              </ul>
              <ul class="details">
                <li class="topic">Sales</li>
                <li><a href="#">Delivered</a></li>
                <li><a href="#">Pending</a></li>
                <li><a href="#">Returned</a></li>
                <li><a href="#">Delivered</a></li>
                <li><a href="#">Pending</a></li>
                <li><a href="#">Returned</a></li>
                <li><a href="#">Delivered</a></li>
                <li><a href="#">Pending</a></li>
                <li><a href="#">Delivered</a></li>
              </ul>
              <ul class="details">
                <li class="topic">Total</li>
                <li><a href="#">$204.98</a></li>
                <li><a href="#">$24.55</a></li>
                <li><a href="#">$25.88</a></li>
                <li><a href="#">$170.66</a></li>
                <li><a href="#">$56.56</a></li>
                <li><a href="#">$44.95</a></li>
                <li><a href="#">$67.33</a></li>
                <li><a href="#">$23.53</a></li>
                <li><a href="#">$46.52</a></li>
              </ul> */}
              <Table className="col-md-8" striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Order Date</th>
          <th>Name</th>
          <th>Email</th>
          {/* <th>Total</th> */}
          {/* <th>status</th> */}
          <th>Action</th>
          {/* <th>Edit</th> */}
        </tr>
      </thead>
      <tbody>
     {
     ordersData.map((item,index)=>
        
        <tr>

          <td>{index+1}</td>
          <td>{(item.created_at).split('T')[0]}</td>
          <td>{item.first_name+' '+item.last_name}</td>
          <td>{item.email}</td>
          {/* <td>{item.price}</td> */}
          {/* <td> */}
            {/* <img src={process.env.REACT_APP_BASE_URL+'products/'+item.image_path} className="product-img"/> */}
         {/* </td> */}
         {/* <td><button onClick={()=>{deleteProd(item.id) }} className="btn btn-danger">Delete</button></td> */}
         <td>
            <Link to={'/admin/invoice-show/'+item.id}>
                <span className="btn btn-primary">View</span>
            </Link>
        </td>
        </tr>
        )
    }
        
      </tbody>
            </Table>
            
            <div class="button">
              <Link to="/admin/all-orders">See All</Link>
            </div>
          </div>
          {/* <div class="top-sales box">
            <div class="title">Top Selling Product</div>
            <ul class="top-sales-details">
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test1</span>
                </a>
                <span class="price">$1107</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test3 </span>
                </a>
                <span class="price">$1567</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test2</span>
                </a>
                <span class="price">$1234</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test1</span>
                </a>
                <span class="price">$2312</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test2</span>
                </a>
                <span class="price">$1456</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test3</span>
                </a>
                <span class="price">$2345</span>
              </li>

              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test2</span>
                </a>
                <span class="price">$2345</span>
              </li>
              <li>
                <a href="#">
                  <img src="https://frontdatlandnew.customdev.devopdigital.com/static/media/cd.9201fd764a9d82a696d5.png" alt=""/>
                  <span class="product">Test1</span>
                </a>
                <span class="price">$1245</span>
              </li>
            </ul>
          </div> */}
        </div>
        </div>
        </>
    )

}