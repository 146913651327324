import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link,useNavigate } from 'react-router-dom';
import {Navbar,Nav,Container, NavDropdown} from 'react-bootstrap'
import siteLogo from "assets/DatLandlogo.gif";

export default function Sidebar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigator = useNavigate()
  const user = JSON.parse(localStorage.getItem('user-info'))
  function Logout(){
    localStorage.clear();
    navigator('/login')
  }
  return (
    <>
     {
     localStorage.getItem('user-info') && !localStorage.getItem('user-info').error?
      <Button className='menuBtn' variant="light" onClick={handleShow}>
      &#9776;
      </Button>
    : ''
    }

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={siteLogo} alt="image" className='adminpansitelogo'/>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        <Navbar expand="lg" className="bg-body-tertiary custom_navbar sidebar">
      <Container fluid>
        <Navbar.Brand href="#">Admin Dashboard </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0 menu-Items"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <a href='/'>Visit Site</a>
            {
            localStorage.getItem('user-info') && !localStorage.getItem('user-info').error?
            <>
            <Link to="/admin/dashboard">Dashboard</Link>
            <Link to="/admin/all-products">All Products</Link>
            <Link to="/admin/all-testimonials">All Testimonials</Link>
            {/* <Link to="/add">Product Categories</Link> */}
            <Link to="/admin/all-orders">Order Management</Link>
            <Link to="/admin/all-users">Users Management</Link>
            {/* <Link to="/admin/website-settings">System Settings</Link> */}
            <Link to="/admin/add-testimonial">Add Testimonials</Link>
            <Link to="/admin/website-settings">Website Settings</Link>
            </> 
            :
            <> 
            <Link to="/login">Login</Link>
            </> 
          }
          </Nav>
          {
            localStorage.getItem('user-info')?
            <>
            <Nav><a onClick={Logout} target='_blank'>Logout</a></Nav>
            </>
            :
            ''
          }
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
        </Offcanvas.Body>
      </Offcanvas>

        


    </>
  );
}

 