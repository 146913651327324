import React, { useEffect, useState } from "react";
import { Link,useNavigate } from 'react-router-dom';
import { Container, Table } from "react-bootstrap";
import Swal from "sweetalert2";


export default function Allusers(){
    const [UsersData, setUsersData] = useState([]);
    const navigator = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator('/')
        }else if( JSON.parse(localStorage.getItem('user-info')).role != 1 ){
            navigator('/')
        }
    },[])

    useEffect(() => {
      (async () => {
        try {
          let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-users');
          result = await result.json();
          setUsersData(result);
          console.log("data loaded successfully");
        } catch (error) {
          console.error(error);
        }
      })();
    }, []); // Empty dependency array to run the effect only once

function deleteUser(id){
    (async()=>{
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/delete-user/'+id,{
        method: 'delete'
      });
      result = await result.json();
      if(result.status == '200'){
        (async () => {
            try {
              let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-users');
              result = await result.json();
              setUsersData(result);
              console.log("data loaded successfully,after delete");
            } catch (error) {
              console.error(error);
            }
          })();
        Swal.fire("User Deleted Successfully");

    }else{
        Swal.fire({
        icon:'error',
        title:'Failed',            
         text:"User Couldn't Deleted !!"  
        });
    }
    })()
}

    return (
        <>
        <Container>
        <div className="d-flex  justify-content-between mt-4 mb-4">
        <h2>All Users</h2>
        <Link to="/admin/createuser"><button className="btn btn-primary">Add User</button></Link>
        </div>
        <Table className="col-md-8" striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Email</th>
          {/* <th>Price</th> */}
          <th>Image</th>
          <th>Delete</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
     {
     UsersData.map((item,index)=>
        <tr>
          <td>{index+1}</td>
          <td>{item.name}</td>
          <td>{item.email}</td>
          {/* <td>{item.price}</td> */}
          <td>
            {/* <img src={process.env.REACT_APP_BASE_URL+''+item.image_path} className="User-img"/> */}image
         </td>
         <td><button onClick={()=>{deleteUser(item.id) }} className="btn btn-danger">Delete</button></td>
         <td>
            <Link to={'/admin/update-user/'+item.id}>
                <span className="btn btn-primary">Edit</span>
            </Link>
        </td>
        </tr>
        )
    }
        
      </tbody>
    </Table>
    </Container>

        </>
    );
}

