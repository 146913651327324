import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function Invoice() {
  const { id } = useParams();
  const [orderItems, setOrderItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);

  useEffect(() => {
    (async () => {
      let result = await fetch(
        process.env.REACT_APP_BASE_URL + "api/orderitems/" + id
      );
      result = await result.json();
      if (result.status === "200") {
        setOrderItems(result.data);
        console.log(result.data);
      } else {
        console.warn("Data not found");
      }
    })();
  }, [id]);

  useEffect(() => {
    if (orderItems.length > 0) {
      let orderItem = orderItems[0];
      let orderId = orderItem.order_id;
      (async () => {
        let result = await fetch(
          process.env.REACT_APP_BASE_URL + "api/show-order-details/" + orderId
        );
        result = await result.json();
        if (result.status === "200") {
          setOrderDetails(result.data);
          setShippingCost(result.data.shipping_cost || 0);

          // Calculate the subtotal
          let calculatedTotal = 0;
          orderItems.forEach((item) => {
            calculatedTotal += item.product_price * item.product_qty;
          });

          // Set the total including the shipping cost
          setTotal(calculatedTotal);
        } else {
          console.log("Order details loading failed");
        }
      })();
    }
  }, [orderItems]);

  function changeOrderStatus() {
    let currentStatus = document.getElementById("status").value;
    if (currentStatus === "") {
      Swal.fire("Please Select Status");
    } else {
      let data = { currentStatus };
      (async () => {
        let result = await fetch(
          process.env.REACT_APP_BASE_URL + "api/change-order-status/" + id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        result = await result.json();
        if (result.status === "200") {
          const result = await Swal.fire({
            title: "Update Status",
            text: "Do you want to update the status?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, update it!",
            cancelButtonText: "No, cancel!",
          });

          if (result.isConfirmed) {
            window.location.reload();
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Status could not be updated",
          });
        }
      })();
    }
  }

  const downloadInvoicePDF = () => {
    const invoiceElement = document.getElementById("invoice-content");

    html2canvas(invoiceElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();

      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      pdf.save(`invoice_${id}.pdf`);
    });
  };

  // Calculate the total including shipping cost
  const totalWithShipping =
    parseFloat(total) + parseFloat(orderDetails.shipping || 0);

  return (
    <>
      <div className="d-flex justify-content-center gap-5">
        <div className="invoice" id="invoice-content">
          <div className="invoice-header">
            <h1>Datland Tech Invoice</h1>
            <p>
              Date:{" "}
              {orderDetails.created_at
                ? orderDetails.created_at.split("T")[0]
                : ""}
            </p>
          </div>
          <div className="invoice-details">
            <div>
              <p>Invoice: {orderDetails.id ? "#" + orderDetails.id : "0"}</p>
              <p>
                Status:{" "}
                {orderDetails.status === 1 ? (
                  <span className="status-success">Completed</span>
                ) : (
                  <span className="status-pending">Pending</span>
                )}
              </p>
              <p>
                Customer Name:{" "}
                {orderDetails.first_name && orderDetails.last_name
                  ? orderDetails.first_name + " " + orderDetails.last_name
                  : ""}
              </p>
              <p>Customer Email: {orderDetails.email || " "}</p>
              <p>
                Customer Address:{" "}
                {orderDetails.country
                  ? `${orderDetails.country}, ${orderDetails.city}, ${orderDetails.state}, ${orderDetails.zip}`
                  : " "}
              </p>
              <p>Payment Method: {orderDetails.payment_method || " "}</p>
            </div>
          </div>
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orderItems.map((item, index) => (
                <tr key={index}>
                  <td>
                    <img src={item.product_image} alt="Product" />
                  </td>
                  <td>{item.product_name}</td>
                  <td>{item.product_qty}</td>
                  <td>${item.product_price}</td>
                  <td>${item.product_qty * item.product_price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="invoice-total">
            <p>Shipping: ${orderDetails.shipping || " "}</p>
            <p>Subtotal: ${total}</p>
            <p>
              <strong>Total: ${totalWithShipping.toFixed(2)}</strong>
            </p>
          </div>
        </div>
        <div className="invoice-status">
          <div className="invoice-header">
            <h1>Update Status</h1>
          </div>
          <div className="invoice-details">
            <Form.Select
              aria-label="Default select example"
              name="status"
              id="status"
            >
              <option value="">Change Status</option>
              <option value="1">Completed</option>
              <option value="0">Pending</option>
            </Form.Select>
          </div>
          <div className="invoice-total">
            <button className="btn btn-primary" onClick={changeOrderStatus}>
              Update
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end pdfBtn">
        <button className="btn btn-primary" onClick={downloadInvoicePDF}>
          Download As PDF
        </button>
      </div>
    </>
  );
}
