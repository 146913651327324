import React,{useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'; 
import {Row,Col,Image} from 'react-bootstrap'
import Swal from 'sweetalert2';

export default function Websitesettings(props){
    const { id } = useParams(); 

    const [logo,setLogo] = useState('');
    const [favicon,setFavicon] = useState('');
    const [phone,setPhone] = useState('');
    const [facebookUrl,setFacebookUrl] = useState('');
    const [instagramUrl,setInstagramUrl] = useState('');


    // fetching product data
    useEffect(()=>{
        ( async()=>{
            let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-settings')
            result = await result.json();
            if(result.status == '200'){
                for(let key in result){
                    if(key == 'data'){
                        for( let a in  result[key]){
                            if(result[key][a].name ==  'logo'){setLogo(result[key][a].value)}
                            else if(result[key][a].name ==  'favicon'){ setFavicon(result[key][a].value) }
                            else if(result[key][a].name ==  'phone'){ setPhone(result[key][a].value)}
                            else if(result[key][a].name ==  'facebook_url'){ setFacebookUrl(result[key][a].value) }
                            else if(result[key][a].name ==  'instagram_url'){ setInstagramUrl(result[key][a].value) }
                        }
                            
                        
                    }
                            
                }
                // console.log((result.data[0].name ==  'logo')? result.data[0].name: 'false');
                // setPhone(result.data.name.phone)
                // setFacebookUrl(result.data.name.facebookUrl)
                // setInstagramUrl (result.data.name.instagramUrl)
                // setLogo(result.data.name.logo)
                // setFavicon(result.data.name.favicon)
            }else{
                console.warn('data not found')
            }   
            })()
    },[])


    const formdata = new  FormData()
    async function UpdateWebsitesetting(){
        
        formdata.append('phone',phone);
        formdata.append('facebookUrl',facebookUrl);
        formdata.append('instagramUrl',instagramUrl);        
        if(logo != ''){
            formdata.append('logo',logo);
        }
        if(favicon != ''){
            formdata.append('favicon',favicon);
        }
        
        
       let result = await fetch(process.env.REACT_APP_BASE_URL+'api/update-settings',{
                    method:'POST',
                    body: formdata
                    })

            result = await result.json();
            if(result.status != '200'){
                Swal.fire(result.error);
            }else{
                Swal.fire('Website Settings Updated Successfully')
            }
    }
    return(
        <>
        <h2 className='p-2 websitesettings-hading'>Website Settings</h2>
        <div className='websiteSettingsForm'>
            
        <div className=''>
                <h5>Contact Info Management</h5>
                <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className='form-control' placeholder='Phone' /><br/>    
           
                <h5>Social media Management</h5>
                <input type="text" value={facebookUrl} onChange={(e)=>{setFacebookUrl(e.target.value)}} className='form-control' placeholder='Facebook Url' /><br/>
                <input type="text" value={instagramUrl} onChange={(e)=>{setInstagramUrl(e.target.value)}} className='form-control' placeholder='Instagram Url' /><br/>
        </div> 


        <h5 >Favicon</h5>
        <div className='favicon'>
        <input type="file" id="favicon" onChange={(e)=>{setFavicon(e.target.files[0])}} className='form-control' placeholder='Image' /><br/>
            <div className='logo-function-images'>
            <Image src="https://alexharkness.com/wp-content/uploads/2020/06/logo-2.png" rounded />
            </div>
        </div>
        <h5>Logo</h5>
        <div className='logo'>
        <input type="file" id="logo" onChange={(e)=>{setLogo(e.target.files[0])}} className='form-control' placeholder='Image' /><br/>
      
            <div className='logo-function-images'>
            <Image src="https://alexharkness.com/wp-content/uploads/2020/06/logo-2.png" rounded />
            </div>
            </div>
            
                   
    
        <button onClick={UpdateWebsitesetting} className='btn btn-primary'>Update</button>
        </div>
        
        
                
        {/* <div className='col-sm-6 '>
        <h1 className='my-5'>Update Website Settings</h1>

        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control' placeholder='Name' /><br/>
        <input type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} className='form-control' placeholder='Description' /><br/>
        <input type="number" value={price} onChange={(e)=>{setPrice(e.target.value)}} className='form-control' placeholder='Price' /><br/>
         <input type="file" onChange={(e)=>{setImage(e.target.files[0])}} className='form-control' placeholder='Image' /><br/>
        <button onClick={UpdateWebsitesetting} className='btn btn-primary'>Update</button>
        </div> */}
  
        </>
    );
}

