// MyPaymentForm.js
import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Swal from 'sweetalert2';

function MyPaymentForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable the submit button.
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    // Use the CardElement to create a payment method
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
  
    if (result.error) {
      setError(result.error.message);
    } else {
      const paymentMethodId = result.paymentMethod.id; // Get the payment method ID
  
      // Send the payment method ID to your server for further processing
      const paymentData = {
        payment_method_id: paymentMethodId,
        amount: 1000, // Specify the payment amount in cents
      };
  
      const submitPayment = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_BASE_URL+'api/charge', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(paymentData), // Send the payment method ID and amount
          });
  
          if (response.ok) {
            // Payment successful
            Swal.fire('Thaks,Payment confirmed');
          } else {
            // Handle payment error
            console.error('Payment error:', response.statusText);
          }
        } catch (error) {
          console.error('Payment error:', error);
        }
      };
      submitPayment();
    }
  };
  

 



  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Card details</label>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </div>
      {error && <div className="error">{error}</div>}
      <button type="submit">Pay</button>
    </form>
  );
}

export default MyPaymentForm;
