import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyPaymentForm from 'pages/MyPaymentForm'; 

const stripePromise = loadStripe('pk_test_51MgbxjEilGowYMOJ9pZ7ZMuer7So6zuTCOcnoKa7A7YdH8ZJ9XOdb8j6xa9Qo3bE3O7FsOiXcTRZoFoOyekMIf0h00szPnwgCA');

function StripePaymentComponent() {
  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await stripePromise;
      // You can use 'stripe' object to interact with Stripe services
    };

    initializeStripe();
  }, []);

  return (
    <>
    <div class="p-5">
    <Elements stripe={stripePromise}>
      <MyPaymentForm />
    </Elements>
    </div>
    </>
  );
}

export default StripePaymentComponent;
