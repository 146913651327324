import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React,{useState,useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// IMAGES
import sliderimage from "assets/slider-img.png";
import backgroundImage from "assets/background-1.png";
import harddriveImage from "assets/hard-drives.png";
import featuredImage from "assets/cd.png";
import roamImage from "assets/cd-room.png";
import harddiskImage from "assets/hard-disk.png";
import featuredImageone from "assets/hard.png";
import featuredImagetwo from "assets/gb.png";
import featuredImagethree from "assets/cpu.png";
import aboutImage from "assets/about-img.png";
import bgImage from "assets/bg-about.png";
import machineImage from "assets/machine.png";
import bgwhatsImage from "assets/bg-whats.png";
import menImage from "assets/men.png";
import menImageone from "assets/men1.png";
import menImagetwo from "assets/men3.png";
import Homeslider from "components/Homeslider";
import bsimg01 from "assets/hard-drives.png";
import bsimg02 from "assets/bestseller02.png";
import bsimg03 from "assets/bestseller03.png";
import bsimg04 from "assets/bestseller04.png";






const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: (
    <span>
      <FontAwesomeIcon icon={solid("arrow-right")} />
    </span>
  ),
  prevArrow: (
    <span>
      <FontAwesomeIcon icon={solid("arrow-left")} />
    </span>
  ),
};

const reviews = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
};

export default function Home() {
  const [productsData, setProductsData] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
        result = await result.json();
        setProductsData(result);
        console.log("data loaded successfully");
      } catch (error) {
        console.error(error);
      }
    })();
  }, []); // Empty dependency array to run the effect only once
  const [testimonialsData, setTestimonialsData] = useState([]);
  (async () => {
             try {
               let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-testimonials');
               result = await result.json();
               setTestimonialsData(result);
               console.log("data loaded successfully,after delete");
             } catch (error) {
               console.error(error);
             }
           })();



  return (
    <>
      <Homeslider />

      {/* BANNER__END */}
      <div className="new-arrivals">
        <Container>
          <Row>
            <Col md={6} className="left-box">
              <div className="product-box-1">
                <Row>
                  <Col md={6}>
                    <div className="inner-box">
                      <strong>NEW ARRIVALS</strong>

                      <h2>PLACE YOUR TITLE HERE</h2>

                      <Link to="/products">
                        SHOP NOW <FontAwesomeIcon icon={solid("arrow-right")} />{" "}
                      </Link>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="product-img">
                      <img src={harddriveImage} alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="product-box-2">
                <Row>
                  <Col md={6}>
                    <div className="product-img">
                      <img src={roamImage} alt="" />
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="inner-box">
                      <strong>NEW ARRIVALS</strong>

                      <h2>PLACE YOUR TITLE HERE</h2>

                      <Link to="/products">
                        SHOP NOW <FontAwesomeIcon icon={solid("arrow-right")} />{" "}
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6} className="right-box">
              <Row>
                <div className="main-product">
                  <span>
                    50% <br /> off
                  </span>
                  <div className="new-disk">
                    <img src={harddiskImage} alt="" />
                  </div>

                  <div className="disk-text">
                    <strong>NEW ARRIVALS</strong>

                    <h2>
                      PLACE YOUR <br /> TITLE HERE{" "}
                    </h2>

                    <Link to="/products">
                      SHOP NOW <FontAwesomeIcon icon={solid("arrow-right")} />{" "}
                    </Link>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* BANNER__END */}

      {/* featured */}
      <div className="fea-slider">
        <Container>
          <div className="featured__slider">
            <h2>Featured Products</h2>
            <Slider {...settings}>
            {
                productsData.map((item,index)=>
                (index <10)? 
              <div>
                <div className="feaut-img">
                  <Link to={"/product-detail/"+item.id} >
                  <img src={  ( ( ( (item.images).split(",")[1] ).split('|')[0] ).replace('Product Image URL: ','') ).trim()  } alt={item.name} />
                  </Link>
                </div>
                <div className="feaut__text">
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                  </ul>
                  <div className="feaut__reviews">
                    <span>Reviews (4)</span>
                  </div>
                </div>
                <div className="slide__bottom__text">
                  <strong>
                    <Link to={"/product-detail/"+item.id}>{item.name}</Link>
                  </strong>
                  {/* <p>{(item.description).substr(1,200)}
                  </p> */}
                  <span>
                    ${item.calculated_price}{" "}
                  </span>
                  <span className="stock-color">
                           {(item.product_availability == 'Instock')? 
                           <>
                           <FontAwesomeIcon icon={solid("circle-check")}/>
                           Instock
                           </>
                           : item.product_availability
                           }
                        </span>
                </div>
              </div>
              : ''
                )

            }
            
            </Slider>
          </div>
        </Container>
      </div>
      {/* featured end */}

      <div className="about-section">
        <Container>
          <Row className="align-items-center inner__about">
            <Col md={5}>
              <div className="about-img">
                <img src={aboutImage} alt="" />
              </div>
            </Col>

            <Col md={6}>
              <div className="about-text">
                <h2>About Us</h2>
                <p>
                Datland Technologies, Inc. is known as a market leader in the sales, repairs, and technical support of tape back-up drives. With our wide-ranging product knowledge and years of expertise, we specialize in all varieties, including 4mm, 8mm, Travan, AIT, DLT, SDLT, LTO, Libraries, and Autoloaders. We have a host of trained technicians who ensure that your tape drive reaches its promised lifespan and continues to serve you for long-term data storage. Our knowledgeable technical support team offers free troubleshooting services around the clock to help you diagnose and correct problems with your tape back-up drive anywhere, anytime. Our customers keep coming back for unparalleled quality back-up and repair solutions: we maintain a large clientele worldwide. Call us today to upgrade or repair your tape back-up drive and we will be happy to help you.
                </p>

                <p>
                We pride ourselves on being a progressive company that stays at the forefront of the industry. We stock efficient back-up solutions from all the top brands, ensuring that our customers have access to the latest and most reliable technology. Whether you are in persistent need of a new drive or looking for a cost-effective repair solution, we have you covered. Not only do we offer new and refurbished tape back-up drives, but we also buy (as-is) drives, providing you with an opportunity to save significantly. Our commitment to offering high-quality and budget-friendly options sets us apart, enabling our customers to find the right solution at the best value.
                </p>

                <Link to="/about" className="blue__btn">
                  Learn More
                  <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* sellers */}
      <div className="best-slider">
        <Container>
          <div className="best__slider">
            <h2>BEST SELLER</h2>
            <Slider {...settings}>
            {
                productsData.map((item,index)=>
              (index > 10 && index < 20 )?
              <div>
                <div className="feaut-img">
                  <Link to={"/product-detail/"+item.id} >
                  <img src={  ( ( ( (item.images).split(",")[1] ).split('|')[0] ).replace('Product Image URL: ','') ).trim()  } alt={item.name} />
                  </Link>
                </div>
                <div className="feaut__text">
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                    <li>
                      <FontAwesomeIcon icon={solid("star")} />
                    </li>
                  </ul>
                  <div className="feaut__reviews">
                    <span>Reviews (4)</span>
                  </div>
                </div>
                <div className="slide__bottom__text">
                  <strong>
                    <Link to={"/product-detail/"+item.id}>{item.name}</Link>
                  </strong>
                  {/* <p>{(item.description).substr(1,200)}
                  </p> */}
                  <span>
                    ${item.calculated_price}{" "}
                  </span>
                  <span className="stock-color">
                           {(item.product_availability == 'Instock')? 
                           <>
                           <FontAwesomeIcon icon={solid("circle-check")}/>
                           Instock
                           </>
                           : item.product_availability
                           }
                        </span>
                </div>
              </div>
              :''
                )

            }
            </Slider>
          </div>
        </Container>
      </div>
      {/* featured end */}

      {/* whats new */}

      <div
        className="main__whatsnew"        
      >
        <Container>
          <Row>
            <Col md={6}>
              <div className="inner__whatsnew">
                <strong>what’s new </strong>
                <h2>PLACE YOUR TITLE HERE</h2>
                <Link to="/products" className="white__btn">
                  Shop Now <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>

            <Col md={5}>
              <div className="whats__image">
                <img src={machineImage} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* whats new end*/}

      {/* reviews */}
      <div className="clients-rev">
        <Container>
          <div className="head__reviews">
            <h2>What Our Clients Say</h2>
            <Slider {...reviews}>
            {
            testimonialsData.map((item,index)=>
              <div className="skew__reviews">
                <div className="top-reviews">
                  <div className="main__reviews">
                    <img src={item.image_path} alt="" />
                  </div>
                  <div className="box__back">
                    <div className="reviews__stars">
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={solid("star")} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={solid("star")} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={solid("star")} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={solid("star")} />
                        </li>
                        <li>
                          <FontAwesomeIcon icon={solid("star")} />
                        </li>
                      </ul>
                    </div>
                    <div className="reviews__text">
                      <p>
                        {item.comment}
                      </p>
                      <strong>{item.name}</strong>
                    </div>
                  </div>
                </div>
              </div>
            )
            }
           
            </Slider>
          </div>
        </Container>
      </div>
    </>
  );
}
