import React,{useState,useEffect} from 'react';
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2';


export default function Createuser(){

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const navigator = useNavigate ();  
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator('/')
        }else if( JSON.parse(localStorage.getItem('user-info')).role != 1 ){
            navigator('/')
        }
    },[])


    // useEffect(()=>{
    //     if(localStorage.getItem('user-info')){
    //         navigator('/add')
    //     }
    // },[])

    async function signUp(){
        let data = {name,email,password}
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/register',{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify(data)
        })
        result = await result.json();
        Swal.fire('User Added Successfully')
        // if(result)
        // save in local storage
        // localStorage.setItem('user-info',JSON.stringify(result))
        navigator('/admin/all-users')   

    }
    return(
        <>
        
        <div className='col-sm-6 formDesign'>
        <h1>Add User</h1>
        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control' placeholder='name' /><br/>
        <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className='form-control' placeholder='email' /><br/>
        <input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} className='form-control' placeholder='password' /><br/><br/>
        <button onClick={signUp} className='btn btn-primary'>Create</button>
        </div>

        </>
    );
}

