import React from 'react';

function Pagination({ currentPage, itemsPerPage, totalItems, onPageChange }) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination products-page">
      {pageNumbers.map((number) => (
        <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
          <button className="page-link" onClick={() => onPageChange(number)}>
            {number}
          </button>
        </li>
      ))}
    </ul>
  );
}

export default Pagination;
