import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function RefundPolicy() {
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Refund Policy</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Refund Policy</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4 refund-policy">
        <Container>
          <div className="main__privacy">
            <div className="privacy__text">
              <h3>Refund Policy</h3>
              <p>
                {" "}
                Thank you for choosing DatLand Technologies, Inc. for your tape back-up drive needs. We aim to provide you with the highest level of service and satisfaction. This Refund Policy clarifies our policy regarding refunds and returns for purchases made through our Website
              </p>
          <ol>
          <li><strong> Returns and Refunds</strong></li>
          </ol>
          <p>We accept returns and provide refunds under the following circumstances:</p>
           
          <ul>
          <li><strong> Damaged or Defective Products</strong>: If you receive a tape back-up drive that is damaged or defective, please link with our customer service within seven days of receiving the product. We will guide you through the return process, and upon receipt and inspection of the returned item, we will issue a complete refund or provide a replacement, as per your preference.</li>
          <li><strong> Incorrect Product:</strong> If you receive an incorrect tape back-up drive different from what you ordered, please notify our client service within seven days of receiving the product. We will arrange for the return of the incorrect item and provide you with the correct product at no additional cost.</li>
          </ul>
           
          <ol start="2">
          <li><strong> Refund Procedure</strong></li>
          </ol>
          <p>To initiate a return and refund, please get in touch with our customer service by email or phone, as provided on our Website. Our team will guide you through the required steps and provide you with a return merchandise authorization (RMA) number.</p>
           
          <p>Please confirm that the returned item is securely packaged and includes all original accessories, documentation, and packaging. We strongly suggest using a trackable shipping facility for your return, as we cannot guarantee the receipt of returned items.</p>
           
          <p>After obtaining the returned item, we will examine it to ensure that it meets our return criteria. Once the return is approved, we will promptly procedure the refund to your original payment method. Please note that it may take 5-7 business days for the refund to reflect in your account, depending on your payment provider's policies.</p>
           
          <ol start="3">
          <li><strong> Non-Refundable Items</strong></li>
          </ol>
          <p>Certain items are non-refundable and not eligible for return, including:</p>
           
          <ul>
          <li>Consumable or disposable items.</li>
          <li>Products with missing, damaged, or altered serial numbers or identification tags.</li>
          <li>Products that have been mishandled, misused, or neglected.</li>
          </ul>
           
          <ol start="4">
          <li><strong> Shipping Charges</strong></li>
          </ol>
          <p>Unless the return is due to an error on our part or a defective product, shipping charges for returns are the accountability of the customer and are non-refundable.</p>
           
          <ol start="5">
          <li><strong> Exchange Policy</strong></li>
          </ol>
          <p>If you wish to exchange a product for a different model or brand, please get in touch with our customer service to discuss availability and options. Additional charges may apply to conceal any price differences.</p>
           
          <ol start="6">
          <li><strong> Contact Us</strong></li>
          </ol>
          <p>If you have any questions or need further assistance regarding our refund policy, please reach out to our customer facility by email at [email protected] or by phone at 714-248-9797. We are here to help and ensure your satisfaction.</p>
           
          <p>Please note that this refund policy is subject to alteration without prior notice. We encourage you to study this policy periodically to stay informed of any updates.</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
