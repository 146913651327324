import React,{useState,useEffect,useRef} from 'react';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom'
import Header from 'admin/components/Dashboardheader'
import Allproducts from 'admin/pages/Allproducts'
import Dashboard from 'admin/pages/Dashboard'
import Swal from 'sweetalert2';

export default function LoginPage() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userInfo, setUserInfo] = useState("");
    const hasLoggedIn = useRef(false); // Using a ref to track if login logic has already executed
    const navigator = useNavigate();  
  
    useEffect(()=>{
      if(localStorage.getItem('user-info') != null ){
          navigator('/')
      }
  },[])
  
    // useEffect(() => {
    //   let user  = JSON.parse(localStorage.getItem('user-info'))
  
    //   if (!hasLoggedIn.current && user) {
    //     if(user.error){
    //       if (user.error !== '') {
    //         alert('Email/Password Is Incorrect');
    //       } else {
    //       setTimeout(() => {
    //         alert('Logged in Successfully');
    //          window.location.reload()
    //         navigator('/');
    //       }, 1000);
    //       }
    //     }else{
    //       // window.location.reload()
    //       navigator('/');
    //     } 
    //     hasLoggedIn.current = true; // Set the flag to true
        
    //   }

    // }, [userInfo]);
  

    
    async function Login(){
      let data = {email,password}
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/login',{
          method:'POST',
          headers:{
              'Content-Type':'application/json',
              'Accept':'application/json'
          },
          body:JSON.stringify(data)
      })
      result = await result.json();
      if(result.error){
        if(result.error == ''){
          // save in local storage
          localStorage.setItem('user-info',JSON.stringify(result.user))
          setUserInfo(JSON.stringify(result))
          navigator(<Dashboard/>)   
        }else{
          Swal.fire('Email/Password does not match !')
        }
      }else{
        localStorage.setItem('user-info',JSON.stringify(result.user))
        setUserInfo(JSON.stringify(result))
        navigator(<Dashboard/>) 
        window.location.reload()
      }


  }
  

  return (
    <>
    {
         localStorage.getItem('user-info') && !localStorage.getItem('user-info').error? 
         navigator('/')
        :
        <>
        {/* BANNER */}
        <div className="in-page-banner">
            <Container>
              <h1 className="text-center">Login</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">Login</li>
              </ol>
            </Container>
          </div>
          {/* END BANNER */}      

          <div className="login-form pt-5 pb-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={6}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter Your Email" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password" />
                    </Form.Group>
                    <Button variant="link" className="blue__btn btn-style round-btn" onClick={Login}>Login</Button>
                  </Form>
                  <div className="or-option">
                    <span>-- OR --</span>
                    <p>Click here to <Link to="/register">Create Your Account</Link></p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
    }
    </>
  );
}
