import React from "react";
import { Container} from "react-bootstrap";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Privacy Policy</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Privacy Policy</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-5 pb-4">
        <Container>
          <div className="main__privacy">
           
                  <div className="privacy__text">
                    <h3>Privacy Policy</h3>
                    <p> At DatLand Technologies, Inc., we are committed to protecting and respecting your privacy. This Privacy Policy summarizes how we collect, use, and protect your info when you visit our Website or interact with our services. We encourage you to read this Policy carefully to understand our practices and how we handle your information.</p>
                    <p><strong>Information We Collect</strong></p>
                <p>When you visit our Website or interact with our services, we may collect certain personal information from you. It may include your name, contact details, email address, and any other information you provide to us voluntarily.</p>
                
                <p><strong>How We Use Your Information</strong></p>
                <p>We use the personal information we collect to provide you with the products and services you request. It includes processing your purchases, delivering order confirmations, and providing support for any inquiries or technical issues you may have. We may also use your information to send you important updates and communications regarding our products and services.</p>
                
                <p><strong>Data Security</strong></p>
                <p>We take data security seriously and have executed appropriate measures to protect your private information from unauthorized access, alteration, disclosure, or destruction. However, please note that no approach to transmission over the Internet or automated storage is completely secure, and therefore, we cannot guarantee absolute security.</p>
                
                <p><strong>Sharing Your Information</strong></p>
                <p>We may share your personal information with our trusted partners, vendors, and service providers who assist us in delivering our products and facilities to you. However, we will only share the information necessary to fulfill our obligations and always ensure that appropriate safeguards are in place.</p>
                
                <p><strong>Third-Party Websites</strong></p>
                <p>Our site may comprise links to third-party websites which have their privacy policies. We are not responsible for the practices, content, or policies of these websites. We encourage you to study the privacy policies of any third-party websites you visit.</p>
                
                <p><strong>Your Rights</strong>&nbsp;</p>
                <p>You have the right to access, update, or request the deletion of your personal information held by us. If you would like to exercise these rights or have any questions or concerns regarding your personal information, please get in touch with us using the information provided below.</p>
                
                <p><strong>Policy Updates</strong></p>
                <p>We may update this Privacy Policy from time to time to reflect alterations in our repetitions or legal requirements. We encourage you to review this Policy periodically for any updates. Your continued utilization of our Website and services after any changes indicate your acceptance of the updated Privacy Policy.</p>
                
                <p><strong>Contact Us</strong></p>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy regulations, please get in touch with us at <a href="mailto:info@datland.com">info@datland.com</a> or through the contact information provided on our Website.</p>
                
                <p>Thank you for trusting DatLand Technologies, Inc. with your personal information. We are dedicated to protecting your privacy and providing you with excellent service.</p>
                  </div>
               
           
          </div>
        </Container>
      </div>
    </>
  );
}
