import React, { useState,useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Swal from "sweetalert2";
 

function SearchProducts() {
  

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  
    // Implement the search logic here
    const allProducts = [];

// for fetching products data dynamically
const [productsData, setProductsData] = useState([]);

useEffect(() => {
  (async () => {
    try {
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
      result = await result.json();
      setProductsData(result);
      console.log("products loaded successfully");
    } catch (error) {
      console.error(error);
    }
  })();
}, []); 

productsData.forEach(element=> {
  allProducts.push(element.name);
});

  
  function filterProducts(query) {
    return allProducts.filter((product) =>
      product.toLowerCase().includes(query.toLowerCase())
    );
  }
  useEffect(() => {
    const results = filterProducts(searchQuery);
    setSearchResults(results);
  }, [searchQuery]);
 
  function toggleSearchResult(value){
    if(value != ''){
      document.querySelector('.searchBar ul.search-results').style.display="block"
    }else{
      document.querySelector('.searchBar ul.search-results').style.display="none"
    }
  }

  function searchButtonun(){
    if(searchQuery == '' ){ 
        Swal.fire("Enter Any Product Name !!");      // setSearchQuery(value);toggleSearchResult(value);
      // document.querySelector('.searchBar ul.search-results').style.display="block"
    }
  }
  

  return (
    <div className="searchBar">
      <div className='innersearchBar'>
      <input
        type="text"
        placeholder="Find your products"
        value={searchQuery}
        onChange={(e) => {setSearchQuery(e.target.value);toggleSearchResult(e.target.value);}}
      />
      <Button variant="outline-secondary" id="search-bar" onClick={searchButtonun}>
          <FontAwesomeIcon icon={solid("magnifying-glass")}  />
        </Button>  
      </div>
      <ul className="search-results">   
        {
          <>
          {
          (searchResults.length > 0)?
          searchResults.map((result, index) => (
            <li key={index}><a href={"/product-detail/1?searched="+result}>{result}</a>
            <a href={"/product-detail/1?searched="+result} className='viewproductbtn'><FontAwesomeIcon icon={solid("magnifying-glass")}  /></a>
            </li>

          )):
          <li key={0}><a href="">No Result Found !!</a></li>
          } 
        </>
        }
      </ul>
    </div>
  );
}

export default SearchProducts;
