import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Button, 
  Form,
  FloatingLabel,
} from "react-bootstrap"; // Import Reactstrap components if not already imported
import { Link } from "react-router-dom";
import featuredImageone from "assets/hard.png";
import Swal from "sweetalert2";

export default function Cart() {
  let cartData = JSON.parse(localStorage.getItem('cart')) ?? [];
  let total = 0;
  
  function plus(index){
    let newCart = [...cartData]
    
    newCart[index].product_qty += 1
    localStorage.setItem('cart',JSON.stringify(newCart))
    setTimeout(() => {
      (async () => {
        await Swal.fire('Quantity Updated Successfully')
        window.location.reload();
      })();
    }, 500);
  }
  function minus(index){
    let newCart = [...cartData]
    newCart[index].product_qty -= 1
    localStorage.setItem('cart',JSON.stringify(newCart))
    setTimeout(() => {
      (async () => {
        await Swal.fire('Quantity Updated Successfully')
        window.location.reload();
      })();
    }, 500);
  }
  function removeItem(index){
     let updatedCartData = [...cartData]
       updatedCartData.splice(index,1)
       setTimeout(() => {
        (async () => {
          await Swal.fire('Item Removed Successfully');
          window.location.reload();
        })();
      }, 500);
    localStorage.setItem('cart',JSON.stringify(updatedCartData))
  }


  if (cartData != null) {
    cartData.forEach((item) => {
      total += parseInt(item.product_price*item.product_qty);
    });
  }
  
  
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Cart</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Cart</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4">
        <div className="main__cart">
          <Container>
            <Row>
              <Col md={8}>
                <div className="inner__cart">
                  <Table Table bordered>
                    <thead>
                      <tr>
                        <th scope="col" className="Product">
                          Product
                        </th>
                        <th scope="col" className="pname-quan">
                          Quantity
                        </th>
                        <th scope="col" className="pname-total">
                          Total
                        </th>
                      </tr>
                    </thead>

                    <tbody> 
                      {
                       (cartData != null && cartData != '')? 
                        cartData.map((item,index) => (
                          <tr>
                        <td>
                          <div className="product__bottom">
                            <a href="#">
                              <img src={item.product_image} alt="" />
                            </a>
                            <div className="table__ptext">
                              <a href="">{item.product_name}</a>
                              <span>${item.product_price}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div className="quan-form">
                              <Button className="dec-btn" onClick={()=>{minus(index)}} disabled={ item.product_qty < 2}>
                                <FontAwesomeIcon icon={solid("minus")} />
                              </Button>
                              <Form.Group controlId="formBasicEmail">
                                <Form.Control type="text" value={item.product_qty} />
                              </Form.Group>
                              <Button onClick={()=>{plus(index)}} className="inc-btn">
                                <FontAwesomeIcon icon={solid("plus")} />
                              </Button>
                              <a className="remove__cart cursorPointer" onClick={()=>{removeItem(index)}} >
                                Remove
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p>${item.product_price*item.product_qty}</p>
                        </td>
                        </tr>   
                        ) )
                     : 
                      <tr className="text-center">
                      <td colSpan={3}>                         
                            <h3>No Product In Cart</h3> 
                      </td>           
                      </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={4}>
                <div className="total__tab_section">
                  <div className="totla__inner">
                    <span>Total</span>
                    <div className="total__price">
                      <p>${total}</p>
                    </div>
                  </div>

                  <div className="total__textarea">
                    {/* <strong>Order instructions</strong>
                    <Form className="mt-3">
                      <FloatingLabel
                        controlId="floatingTextarea2"
                        label="Comments"
                      >
                        <Form.Control
                          as="textarea"
                          placeholder="Leave a comment here"
                          style={{ height: "150px" }}
                        />
                      </FloatingLabel>
                    </Form>
                    <p>Tax included and shipping calculated at checkout</p> */}
                    <div className="bottom__total">
                      <a href="/checkout" className="blue__btn">
                        Check Out
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
