import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MyPaymentForm from 'pages/MyPaymentForm'; 

const stripePromise = loadStripe('your_publishable_key_here');

export default function Stripe() {
  useEffect(() => {
    const initializeStripe = async () => {
      const stripe = await stripePromise;
      // You can use 'stripe' object to interact with Stripe services
    };

    initializeStripe();
  }, []);

  return (
    <Elements stripe={stripePromise}>
    <MyPaymentForm />
  </Elements>
  );
}

// import { Elements } from '@stripe/react-stripe-js';
// import MyPaymentForm from 'pages/MyPaymentForm'; // Create this component

// function Stripe() {
//   return (
//     <Elements stripe={stripePromise}>
//       <MyPaymentForm />
//     </Elements>
//   );
// }

// export default Stripe;