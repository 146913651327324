import React from 'react';


export default function Userdashboardsidebar(props) {
  return (
 <>
 <div class="sidebar">
      <div class="logo-details">
        <i class="bx bxl-c-plus-plus"></i>
        <span class="logo_name">Datland Tech</span>
      </div>
      <ul class="nav-links">
        <li>
          <a href="/user-dashboard" className={(props.currentTab == 'dashboard')? 'active' : ''} >
            <i class="bx bx-grid-alt"></i>
            <span class="links_name">Dashboard</span>
          </a>
        </li> 
        <li>
          <a href="/user-orders" className={(props.currentTab == 'orders')? 'active' : ''} >
            <i class="bx bx-book-alt"></i>
            <span class="links_name" >Total order</span>
          </a>
        </li>
        <li>
          <a href="/manage-profile">
            <i class="bx bx-cog"></i>
            <span class="links_name">Manage Profile</span>
          </a>
        </li>
      </ul>
    </div>
  
    </>
  )
}
