import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import sliderimage from "assets/slider-img.png";
import backgroundImage from "assets/background-1.png";

export default function Homeslider() {
  return (
    <div
      className="main__slider"
      
    >
      <Container>
        <div className="inner__slider">
          <Row className="align-items-center">
            <Col md={6}>
              <div className="slider__text">
                <h2>Welcome To</h2>
                <strong>Datland Technologies. Inc</strong>
                <p>
                  We are your go-to source for all your tape back-up drive needs. Whether you're 
                  searching online for a new drive, require repairs, or need technical assistance, 
                  Datland Technologies is the right place to be.
                </p>


                <Link to="/products" className="blue__btn">
                  Shop Now <FontAwesomeIcon icon={solid("arrow-right")} />
                </Link>
              </div>
            </Col>


            <Col md={6}>
              <div className="slider__image">
                <img src={sliderimage} alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
