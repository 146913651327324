import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Terms and Conditions</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Terms and Conditions</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-5 pb-4 terms-conditions">
        <Container>
          <div className="main__privacy">
            <div className="privacy__text">
              <h3>Terms and Conditions</h3>
              <p>
                {" "}
                These Terms and Conditions ("Agreement") govern your usage of the DatLand Technologies, Inc. website ("Website"). By retrieving or using the Site, you consent to abide by and be bound by this Agreement. If you do not agree to these Terms and Conditions, please do not utilize the Website.
              </p>
              <ol>
            <li><strong> Intellectual Property Rights</strong></li>
            </ol>
            <p>The content, materials, and trademarks displayed on the Website are the property of DatLand Technologies, Inc., and are defended by copyright, trademark, and other intellectual property rules. You may not modify, reproduce, distribute, or exploit any of the content without our prior inscribed consent.</p>
              
            <ol start="2">
            <li><strong> Website Use</strong></li>
            </ol>
            <p>You may use the Website for lawful purposes only. You agree not to engage in any activities that may disrupt or interfere with the operation of the Website or compromise its security. We reserve the right to suspend or dismiss your access to the Website if you disrupt these Terms and Conditions.</p>
              
            <ol start="3">
            <li><strong> Information Accuracy</strong></li>
            </ol>
            <p>While we strive to provide accurate and up-to-date information on the Website, we do not guarantee the accuracy, completeness, or reliability of any content. The use of any information obtained from the Website is at your own risk, and we shall not be held liable for any inaccuracies or damages arising from the use of such information.</p>
              
            <ol start="4">
            <li><strong> Products and Services</strong></li>
            </ol>
            <p>Any purchase of products or services through the Website is subject to our separate Terms of Sale. Please review the valid terms and conditions before making any purchases.</p>
              
            <ol start="5">
            <li><strong> Third-Party Links</strong></li>
            </ol>
            <p>The Website may comprise links to third-party websites for your convenience. We do not endorse or assume any accountability for the content, products, or services delivered by these third-party websites. Your use of such websites is at your own risk, and we recommend reviewing their respective terms and conditions and privacy policies.</p>
              
            <ol start="6">
            <li><strong> Indemnification</strong></li>
            </ol>
            <p>You agree to indemnify and hold harmless DatLand Technologies, Inc., its affiliates, officers, directors, employees, and agents from any claims, damages, liabilities, or expenses arising out of your use or misuse of the Website, including any violation of these Terms and Conditions.</p>
              
            <ol start="7">
            <li><strong> Limitation of Liability</strong></li>
            </ol>
            <p>In no event shall DatLand Technologies, Inc., its affiliates, officers, directors, workers, or agents be liable for any indirect, incidental, special, or consequential indemnities arising out of or in connection with the use of the Website, even if informed of the possibility of such damages.</p>
              
            <ol start="8">
            <li><strong> Governing Law and Authority</strong></li>
            </ol>
            <p>These Terms and Conditions shall be administered by and construed in accordance with the laws of our country.</p>
              
            <ol start="9">
            <li><strong> Severability</strong></li>
            </ol>
            <p>If any provision of these Terms and Conditions is verified to be illegal or unenforceable, the remaining anticipations shall remain in full force and effect.</p>
              
            <ol start="10">
            <li><strong> Changes to the Agreement</strong></li>
            </ol>
            <p>We reserve the right to modify or update this Agreement at any time without prior notice. It is your accountability to review this Agreement periodically for any changes. Your continued use of the Website after any modifications signifies your acceptance of the revised Agreement.</p>
              
            <p>If you have any queries or concerns regarding these Terms and Conditions, don't hesitate to get in touch with us at <a href="mailto:info@datland.com">info@datland.com</a></p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
