import React,{useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Login from './pages/Login';
import Products from './pages/Allproducts';

import './index.css'

export default function Protected(props){
    const navigator = useNavigate();
    useEffect(()=>{
        let user  = JSON.parse(localStorage.getItem('user-info'))
            if(user != null){
                if(user.error){
                    if(user.error !== ''){
                        navigator('/admin/login')
                    }
                }else{
                    navigator(<Products/>)
                    
                }
            }
            else{
                navigator(<Login />)
            }
    },[])
    let Comp = props.comp;
    return(
        
        <Comp/>

    );
}
