import React, {useState} from 'react';
import { Col, Container, InputGroup, Form, Row } from 'react-bootstrap';
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';


// import { Form } from 'react-router-dom'

// images 
import footerimage from "assets/discover.svg";
import footerimageone from "assets/paypal.svg";
import footerimagetwo from "assets/round.svg";
import footerimagethree from "assets/Vector.svg";
import footerimagefour from "assets/visa.svg";
import footerImage from "assets/footer-bg.png";
export default function Footer() {
  const [expanded, setExpanded] = useState(false);
  const [subsEmail, setsubsEmail] = useState('');
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  }

  async  function sumitSubscription(){
    if(JSON.parse(localStorage.getItem('user-info')) != null){
      if(subsEmail == '' || !subsEmail.includes('@')){
        Swal.fire({
          icon:'error',
          title:'Failed',
          text:'Please Enter Email !!'
        })
      }else{
        let user = JSON.parse(localStorage.getItem('user-info'));
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/check-subscriber/'+user.id)
        result = await result.json();
        if(result.data == 'true'){
          Swal.fire('You Have Already Subscribed')
        }else{
          let user = JSON.parse(localStorage.getItem('user-info'));
          let result = await fetch(process.env.REACT_APP_BASE_URL+'api/subscription-store',{
            method:'POST',
            headers:{
                'Content-Type':'application/json',
                'Accept':'application/json'
            },
            body:JSON.stringify({'email':subsEmail,'user_id':user.id})
          })
          result = await result.json();
          if(result.status == 200){
            Swal.fire('Success','Subscribed Successfully,Thanks','success')
            setsubsEmail('')
          }else{
            Swal.fire('failed')
          }
        }
      }
    }else{
      Swal.fire('Please Login To Continue !!');
    }

    
  }
  return (
    <>
      <div className='main__footer'>
        <div className='top-footer'>
          <Container>
            <div className='inner__footer'>
              <Row className='justify-content-center'>

                <Col md={5}>
                  <h2>Sign Up To Our Newsletter.</h2>
                  <strong>Be the first to hear about the latest offers.</strong>
                </Col>

                <Col md={6}>
                  <div className='form_sec'>
                    
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Your Email"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={subsEmail}
                          onChange={(e)=>{setsubsEmail(e.target.value)}}
                        />
                        <InputGroup.Text id="basic-addon2" onClick={sumitSubscription}>Subscribe</InputGroup.Text>
                      </InputGroup>

                    
                  </div>
                </Col>
              </Row>

              <div className='footer__content'>
                <Row className='justify-content-center'>

                  <Col md={3}>
                    <div className='footer__content__text'>
                      <strong> Quick Links</strong>
                      <ul>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} /> <Link  to='/' onClick={setExpand}>Home</Link></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} /> <Link  to='/products' onClick={setExpand}>Products</Link></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} />  <Link  to='/testimonials' onClick={setExpand}>Testimonials</Link></li>
                      </ul>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className='footer__content__text'>
                      <strong> Information</strong>
                      <ul>
                      <li><FontAwesomeIcon icon={solid("chevron-right")} /> <Link  to='/about' onClick={setExpand}>About Us</Link></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} /><Link  to='/repair-services'> Repair Services</Link></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} /><Link  to='/contact-us'> Contact Us</Link></li>
                      </ul>
                    </div>
                  </Col>



                 



                  <Col md={2}>
                    <div className='footer__content__text'>
                      <strong>Other Links</strong>
                      <ul>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} />  <a href='/privacy-policy' >Privacy Policy</a></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} />  <a href='/terms-and-conditions' >Terms & Conditions</a></li>
                        <li><FontAwesomeIcon icon={solid("chevron-right")} />  <a href='/refund-policy' >Refund Policy</a></li>
                      </ul>
                    </div>
                  </Col>


                  <Col md={3}>
                    <div className='footer__content__text'>
                      <strong>Contact Information</strong>



                      <div className='email__section'>
                        <span className='spacing__sec'>
                          Address: Lorem Ipsum</span>

                        <span>Phone: <Link to='/'>(714) 248-9797</Link>
                        </span>

                        <span>E-mail:  
                        <a href="mailto:info@datland.com"> info@datland.com</a></span>
                      </div>

                    </div>
                  </Col>

                </Row>
              </div>


            </div>
          </Container>
        </div>
        <div className='footer__bottom'>
          <Container>
            <Row className='justify-content-center'>

              <Col md={3}>
                <div className='footer__icons'>

                <FontAwesomeIcon icon={brands("square-facebook")} />
                <FontAwesomeIcon icon={brands("instagram")} />
                </div>
              </Col>
              <Col md={4}>
                <div className='copy_sec'>
                  <p>Copyright © 2023 -  All Right Reserved.</p>
                </div>
              </Col>



              <Col md={4}>

                <div className='bottom__image'>
                  <div className='price__images'>
                    <img  src={footerimageone} alt="" />
                    <img className='visa-img' src={footerimagefour} alt="" />
                    <img className='round-img' src={footerimagetwo} alt="" />
                    <img className='discover-img' src={footerimage} alt="" />
                    <img className='american-img' src={footerimagethree} alt="" />
                  </div>

                 

                </div>
              </Col>





            </Row>
          </Container>
        </div>
      </div>

    </>
  )
}
