import React,{useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Userdashboardsidebar from 'components/Userdashboardsidebar'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default  function Userinvoice(props){
    const navigator = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null ){
            navigator('/')
        }
    },[])
    const { id } = useParams(); 
    const [orderItems,setOrderItems] = useState([]);
    const [orderDetails,setOrderDetails] = useState([]);
    
    let orderId=0;
    let total = 0;

    // fetching order data
    useEffect(()=>{
        ( async()=>{
            let result = await fetch(process.env.REACT_APP_BASE_URL+"api/orderitems/"+id)
            result = await result.json();
            if(result.status == '200'){
                setOrderItems(result.data)
                console.log(result.data);
            }else{
                console.warn('data not found')
            }   
            })()
    },[])
    useEffect(()=>{
                    <p>Invoice Number:{ (orderDetails.id  )? '#'+orderDetails.id : '0' }</p>

                if(orderItems.length > 0){
                let  orderitem = orderItems[0]
                orderId = orderitem.order_id;
                (async()=>{
                    let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-order-details/'+orderId)
                    result = await result.json();
                    if(result.status == '200'){
                        setOrderDetails(result.data)
                    }else{
                        console.log('order details loading failed')
                    }
                })()
            }
    },orderItems)
    if (orderItems != null) {
        orderItems.forEach((item) => {
          total += parseInt(item.product_price*item.product_qty);
        });
      }

      const downloadInvoicePDF = () => {
        const invoiceElement = document.getElementById('invoice-content');
    
        html2canvas(invoiceElement).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const width = pdf.internal.pageSize.getWidth();
          const height = pdf.internal.pageSize.getHeight();
    
          pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
          pdf.save(`invoice_${id}.pdf`);
        });
      };
      
    return(
        <>
        <div class="userdashboard-main container">
        <Userdashboardsidebar currentTab="orders"/>
        <div className='d-flex justify-content-center gap-5'>
        <div class="invoice" id="invoice-content">
                <div class="invoice-header">
                    <h1>Datland Tech Invoice</h1>
                    <p>Date :  { (orderDetails.created_at  )? (orderDetails.created_at).split('T')[0] : '' }</p>
                </div>
                <div class="invoice-details">
                    <div>
                    <p>Invoice  : { (orderDetails.id  )? '#'+orderDetails.id : '0' }</p>
                    <p>Status : { (orderDetails.status == 1)? 'Completed' : 'Pending' }</p>
                    <p>Customer Name : {(orderDetails.first_name && orderDetails.last_name )? orderDetails.first_name+' '+orderDetails.last_name : '' }</p>
                    <p>Customer Email : { (orderDetails.email  )? orderDetails.email : ' ' }</p>
                    <p>Customer Address : { (orderDetails.country )? orderDetails.country+','+orderDetails.city+','+orderDetails.state+','+orderDetails.zip : ' ' }</p>
                    <p>Payment Method : { (orderDetails.payment_method )? orderDetails.payment_method : ' ' }</p>
                    </div>
                </div>
                <table class="invoice-table">
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                    orderItems.map((item,index)=>(
                        <tr>
                            <td><img src={item.product_image}/></td>
                            <td>{item.product_name}</td>
                            <td>{item.product_qty}</td>
                            <td>${item.product_price}</td>
                            <td>${item.product_qty * item.product_price}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                <div class="invoice-total">
                    <p>Total: ${total}</p>
                </div>
        </div>
            <div class="">
                
            </div>
        </div>
        </div>
        
        <div className='d-flex justify-content-end userinvoice pdfBtn'>
            <button className='btn btn-primary' onClick={downloadInvoicePDF}>
            Download As PDF
            </button>
        </div>
        </>
    );
}

