import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";


export default function RepairServices() {
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Repair Services</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Repair Services</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4 repair-services">
        <Container>
          <div className="main__privacy">
            <div className="privacy__text">
              <h3>Tape Drive Repair</h3>
              <p>
                {" "}
                At our repair center, we offer comprehensive and reliable services for all tape back-up drives. Our qualified technicians are equipped to handle a wide range of issues with your tape back-up drive, including mechanical failures, data corruption, and connectivity issues. No matter what issue you're facing with your tape back-up drive, you can count on us to provide fast, reliable, and cost-effective service. So why wait? Bring your tape back-up drive to our repair center today, and let us get it back up and running like new!
                <br/><br/>Our repairs include key services like:

              </p>
              <ol>
<li><strong>Tape Drive Cleaning:</strong> We use specialized cleaning tools and techniques to remove dust, debris, and other contaminants that may be interfering with your tape back-up drive's performance.</li>
            </ol>
          <ol start="2">
          <li><strong> Tape Drive Repair:</strong> Our technicians are skilled in repairing various mechanical issues, including damaged read/write heads, broken tape guides, and worn-out motors.</li>
          </ol>

          {/* <ol start="3">
          <li><strong> Data Recovery</strong>: Using advanced data recovery tools and techniques, we can retrieve data from damaged or corrupted tape back-up drives.</li>
          </ol>

          <ol start="4">
          <li><strong> Connectivity Troubleshooting:</strong> If you're experiencing connectivity issues with your tape back-up drive, our experts can help identify and resolve the root reason for the problem.</li>
          </ol> */}

          <ol start="3">
          <li><strong> Tape Drive Upgrades:</strong> If you're looking to upgrade your tape back-up drive, we can help you select the right model for your needs and install it for you.</li>
        </ol>         

            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
