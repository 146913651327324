import React, { useEffect, useState } from "react";
// import Header from "./Header";
import { Link,useNavigate } from 'react-router-dom';
import { Container, Table } from "react-bootstrap";


export default function Orders(){
  const [ordersData, setOrdersData] = useState([]);
  const navigator = useNavigate();
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null  ){
            navigator('/')
        }else if( JSON.parse(localStorage.getItem('user-info')).role != 1 ){
            navigator('/')
        }
    },[])
  

//   feth all orders
  useEffect(() => {
    (async () => {
      try {
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-orders');
        result = await result.json();
        setOrdersData(result);
        console.log("orders data loaded successfully");
      } catch (error) {
        console.error(error);
      }
    })();
  }, []); // Empty dependency array to run the effect only once

// function deleteProd(id){
//     (async()=>{
//       let result = await fetch(process.env.REACT_APP_BASE_URL+'api/delete-product/'+id,{
//         method: 'delete'
//       });
//       result = await result.json();
//       if(result.status == '200'){
//         (async () => {
//             try {
//               let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
//               result = await result.json();
//               // setProductsData(result);
//               console.log("data loaded successfully,after delete");
//             } catch (error) {
//               console.error(error);
//             }
//           })();
//         alert("Product Deleted Successfully");

//     }else{
//         alert("Product Couldn't Deleted !!");
//     }
//     })()
// }

    return (
        <>
        {/* <Header/> */}
        <Container>
        <div className="d-flex  justify-content-between mt-4 mb-4">
        <h2>All Orders</h2>
        {/* <Link to="/admin/add-product"><button className="btn btn-primary">Add Product</button></Link> */}
        </div>
        <Table className="col-md-8" striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Order Date</th>
          <th>Name</th>
          <th>Email</th>
          {/* <th>Total</th> */}
          {/* <th>status</th> */}
          <th>Action</th>
          {/* <th>Edit</th> */}
        </tr>
      </thead>
      <tbody>
     {
     ordersData.map((item,index)=>
        
        <tr>

          <td>{index+1}</td>
          <td>{(item.created_at).split('T')[0]}</td>
          <td>{item.first_name+' '+item.last_name}</td>
          <td>{item.email}</td>
          {/* <td>{item.price}</td> */}
          {/* <td> */}
            {/* <img src={process.env.REACT_APP_BASE_URL+'products/'+item.image_path} className="product-img"/> */}
         {/* </td> */}
         {/* <td><button onClick={()=>{deleteProd(item.id) }} className="btn btn-danger">Delete</button></td> */}
         <td>
            <Link to={'/admin/invoice-show/'+item.id}>
                <span className="btn btn-primary">View</span>
            </Link>
        </td>
        </tr>
        )
    }
        
      </tbody>
    </Table>
    </Container>

        </>
    );
}

