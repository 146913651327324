import React, { useState } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import Swal from "sweetalert2";
import PaymentOptions from "pages/PaymentOptions";

export default function Checkout() {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [companyName, setcompanyName] = useState("");
  const [streetAddress, setstreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setzipCode] = useState("");

  let cartData = JSON.parse(localStorage.getItem("cart")) ?? [];
  let navigator = useNavigate();
  let userInfo = JSON.parse(localStorage.getItem("user-info"));
  let userId = userInfo != null ? userInfo.id : 0;

  const [shippingMethod, setShippingMethod] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const shippingOptions = {
    UPS: [
      { label: "USPS Retail Ground", price: 10 },
      { label: "Media Mail Parcel", price: 15 },
      { label: "Library Mail Parcel", price: 20 },
    ],
    FedEx: [
      { label: "Priority mail express, Small Box", price: 25 },
      { label: "Priority mail express, Large Box", price: 30 },
      { label: "Priority mail, Regional Rate Box", price: 35 },
    ],
  };

  // Calculate cart subtotal
  const calculateCartSubtotal = () => {
    return cartData.reduce(
      (total, item) => total + item.product_price * item.product_qty,
      0
    );
  };

  // Function to handle shipping method change
  const handleShippingMethodChange = (method) => {
    setShippingMethod(method);
    if (method && shippingOptions[method]) {
      const defaultOption = shippingOptions[method][0];
      setSelectedOption(defaultOption.label);
      setShippingPrice(defaultOption.price); // Update shipping price
      setTotalPrice(calculateCartSubtotal() + defaultOption.price);
    } else {
      setTotalPrice(calculateCartSubtotal());
    }
  };

  // Function to handle option change
  const handleOptionChange = (option) => {
    setSelectedOption(option.label);
    setShippingPrice(option.price);
    setTotalPrice(calculateCartSubtotal() + option.price);
  };

  async function placeOrder() {
    if (
      firstName == "" ||
      email == "" ||
      contact == "" ||
      streetAddress == "" ||
      city == "" ||
      country == "" ||
      state == "" ||
      zipCode == "" ||
      localStorage.getItem("selectedPaymentMethod") == ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Failed",
        text: "Please Fill Required Data",
      });
    } else {
      if (!email.includes("@")) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: "Please Enter Correct/Complete Email",
        });
      } else {
        let orderItems = JSON.parse(localStorage.getItem("cart"));
        let paymentMethod =
          localStorage.getItem("selectedPaymentMethod") ?? "cashOnDelivery";

        let data = {
          firstName,
          lastName,
          email,
          contact,
          companyName,
          streetAddress,
          city,
          country,
          state,
          zipCode,
          orderItems,
          userId,
          paymentMethod,
          totalPrice,
          shipping: shippingPrice,
        };

        let result = await fetch(
          process.env.REACT_APP_BASE_URL + "api/order/store",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        result = await result.json();
        if (result.status == "200") {
          (async () => {
            await Swal.fire("Thanks", "Order Placed Successfully", "success");
            localStorage.removeItem("cart");
            window.location.reload();
            window.location.href = "/";
          })();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Could Not Complete Order",
          });
        }
      }
    }
  }
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Checkout</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Checkout</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4">
        <div className="top__check__sec">
          <Container>
            <Row>
              <Col md="7">
                <div className="form__checkout">
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>First name *</Form.Label>
                      <Form.Control
                        type="text"
                        value={firstName}
                        onChange={(e) => {
                          {
                            setfirstName(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        value={lastName}
                        onChange={(e) => {
                          {
                            setlastName(e.target.value);
                          }
                        }}
                      />
                    </Form.Group>
                  </Row>

                  <Row className="g-3">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                          type="email"
                          value={email}
                          onChange={(e) => {
                            {
                              setEmail(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Contact *</Form.Label>
                        <Form.Control
                          type="text"
                          value={contact}
                          onChange={(e) => {
                            {
                              setContact(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Company name (optional)</Form.Label>
                        <Form.Control
                          type="text"
                          value={companyName}
                          onChange={(e) => {
                            {
                              setcompanyName(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Street address *</Form.Label>
                        <Form.Control
                          type="address"
                          value={streetAddress}
                          onChange={(e) => {
                            {
                              setstreetAddress(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Town / City *</Form.Label>
                        <Form.Control
                          type="text"
                          value={city}
                          onChange={(e) => {
                            {
                              setCity(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Country / Region *</Form.Label>
                        <Form.Control
                          type="text"
                          value={country}
                          onChange={(e) => {
                            {
                              setCountry(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>State *</Form.Label>
                        <Form.Control
                          type="text"
                          value={state}
                          onChange={(e) => {
                            {
                              setState(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>ZIP Code *</Form.Label>
                        <Form.Control
                          type="number"
                          value={zipCode}
                          onChange={(e) => {
                            {
                              setzipCode(e.target.value);
                            }
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="g-2">
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formGridShipping">
                        <Form.Label>Choose Shipping Method *</Form.Label>
                        <Form.Select
                          value={shippingMethod}
                          onChange={(e) =>
                            handleShippingMethodChange(e.target.value)
                          }
                        >
                          <option value="UPS">UPS</option>
                          <option value="FedEx">FedEx</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={5}>
                <div className="check__table sticky-top">
                  <h2>Your Order</h2>
                  <Table className="shop_table">
                    <thead className="mb-4">
                      <tr>
                        <th className="product-name">Item</th>
                        <th className="product-name">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData != null
                        ? cartData.map((item, index) => (
                            <tr>
                              <td>
                                <div className="product__bottom">
                                  <a href="#">
                                    <img src={item.product_image} alt="" />
                                  </a>
                                  <div className="table__ptext">
                                    <a href="">{item.product_name}</a>
                                    <span>${item.product_price}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p>${item.product_price * item.product_qty}</p>
                              </td>
                            </tr>
                          ))
                        : ""}
                    </tbody>
                    <tfoot>
                      <tr>
                        <th className="product-name">Shipping</th>
                      </tr>
                      {shippingMethod &&
                        shippingOptions[shippingMethod].map((option, index) => (
                          <tr key={index}>
                            <td>
                              <div className="product__bottom">
                                <input
                                  type="radio"
                                  name="shippingOption"
                                  checked={selectedOption === option.label}
                                  onChange={() => handleOptionChange(option)}
                                />
                                <div className="table__ptext">
                                  <span>{option.label}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <p>${option.price}</p>
                            </td>
                          </tr>
                        ))}
                      {/* Total Price Row */}
                      <tr>
                        <td colSpan="2">
                          <div className="total-price">
                            <strong>Total: ${totalPrice}</strong>
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>

                  <div className="bottom__check">
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Laboriosam maiores quo fugiat ipsam nobis, porro deserunt
                      commodi iste fugit suscipit eveniet molestias sit
                      repellendus officiis cupiditate. Quae doloribus nobis
                      quidem!
                    </p>
                    <div className="bottom__check__btn">
                      {
                        <>
                          <Form>
                            {["checkbox"].map((type) => (
                              <div key={`default-${type}`} className="mb-3">
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={`I have read and agree to the website terms and conditions * `}
                                  defaultChecked
                                />
                              </div>
                            ))}
                          </Form>
                          <p>Payment Method (Default COD)</p>
                          <PaymentOptions />
                          <a
                            onClick={placeOrder}
                            className="blue__btn cursorPointer"
                          >
                            Place Order
                          </a>
                        </>
                      }
                    </div>

                    <div className="bottom__check__btn">
                      <a className="white__btn" href="/products">
                        Continue Shopping
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}
