import React, {useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import featuredImage from "assets/cd.png";

import featuredImageone from "assets/hard.png";
import featuredImagetwo from "assets/gb.png";

import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Wishlist() {
  const [expanded, setExpanded] = useState(false);
  const setExpand = () => {
    setExpanded(false);
    window.scrollTo(0, 0);
  }
  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Wishlist</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Wishlist</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="main__wishlist">
        <Container>
        <Row>
              <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImage} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImagetwo} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImagetwo} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImagetwo} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImagetwo} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImagetwo} alt="" />
                    </Link>
                  </div>

                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
                </Col>
                <Col md={4}>
                <div className="total__product__sec">
                  <div className="product__image feaut-img">
                    <Link to="/product-detail" onClick={setExpand}>
                      <img src={featuredImageone} alt="" />
                    </Link>
                  </div>
                  <div className="feaut__text">
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                      <li>
                        <FontAwesomeIcon icon={solid("star")} />
                      </li>
                    </ul>
                    <div className="feaut__reviews">
                      <span>Reviews (4)</span>
                    </div>
                  </div>
                  <div className="slide__bottom__text">
                    <strong>
                      <Link to="/product-detail" onClick={setExpand}>place your heading title here</Link>
                    </strong>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the
                    </p>
                    <span>
                      $499.00 <del>$499.00 </del>{" "}
                    </span>
                    <span className="stock-color">
                      <FontAwesomeIcon icon={solid("circle-check")} /> in stock
                    </span>
                  </div>
                </div>
              </Col>
              </Row>
        </Container>
      </div>
    </>
  );
}
