import React,{useState,useEffect} from 'react';
import { Link,useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button,Image } from "react-bootstrap";
import Swal from 'sweetalert2';
import Userdashboardsidebar from 'components/Userdashboardsidebar'




export default function Manageprodfile() {
  const navigator = useNavigate();  
    useEffect(()=>{
        if(localStorage.getItem('user-info') == null ){
            navigator('/')
        }
    },[])
  
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [oldPassword,setOldpassword] = useState("");
  const [newPassword,setNewpassword] = useState("");
  const [newcPassword,setNewcpassword] = useState("");
    
  let userInfo = JSON.parse(localStorage.getItem('user-info')); 
  let id = 22;

  

 // fetching user data
 useEffect(()=>{
    ( async()=>{
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/user-details/'+id)
        result = await result.json();
        if(result.status == '200'){
            setName(result.data.name)
            setEmail(result.data.email)
            
            // setImage(result.data.image_path)
        }else{
            console.warn('data not found')
        }   
        })()
},[])


function update(){
    // if(newPassword== '' || newcPassword==''){
    //     Swal.fire({
    //         'icon':'error',
    //          'title':'Failed',
    //          'text':'Enter New Password/Confirm Password'
    
    //       })  
    // }
    if(newPassword != newcPassword){
      Swal.fire({
        'icon':'error',
         'title':'Failed',
         'text':'Password & Confirm Password do not match'

      })
    }else{
        const formdata = new  FormData()
        // async function update(){
            formdata.append('name',name);
            // formdata.append('description',description);
            if(newPassword != ''){
              formdata.append('password',newPassword);            
            }

            // if(image != ''){
            //     formdata.append('image',image);
            // }
        (async()=>{
            let result = await fetch(process.env.REACT_APP_BASE_URL+'api/update-user/'+id,{
                    method:'POST',
                    body: formdata
                })
            result = await result.json();
            Swal.fire('Profile Updated Successfully')
            // save in local storage
            localStorage.setItem('user-info',JSON.stringify(result));
        })()
   
    }
  } 


  return (
    <>
    <div class="userdashboard-main container">
    <Userdashboardsidebar currentTab="dashboard"/>

      <div className="home-content login-form">
        <Container>
          <Row className="justify-content-center">
            
            <Col md={12}>
              <Form>
              {/* <Col xs={6} md={4}>
                <Image src="holder.js/171x180" rounded />
            </Col> */}
              {/* <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Profile</Form.Label>
                  <Form.Control type="file" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Username" />
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Username" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter Your Email" disabled={true}/>
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control type="password" value={oldPassword} onChange={(e)=>{setOldpassword(e.target.value)}} placeholder="Password" />
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="formBasicCPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" value={newPassword} onChange={(e)=>{setNewpassword(e.target.value)}} placeholder="Confirm Password" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control type="password" value={newcPassword} onChange={(e)=>{setNewcpassword(e.target.value)}} placeholder="Confirm Password" />
                </Form.Group>
                <Button
                  variant="link"
                  className="blue__btn btn-style round-btn"
                  onClick={update}
                >
                  Update Profile
                </Button>
              </Form>
              <div className="or-option">
                {/* <span>-- OR --</span>
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
    </>
  );
}
