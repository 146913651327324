import React,{useState} from "react";

export default function UpdateCart(props) {

    const [count,setCount] = useState('');
    setCount(props.count)
    
  return count;

}
