import React,{useState,useEffect} from 'react';
import { Link,useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';

export default function RegisterPage() {
  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [cpassword,setcPassword] = useState("");
  const navigator = useNavigate ();  
  useEffect(()=>{
      if(localStorage.getItem('user-info')){
          navigator('/')
      }
  },[])

  async function signUp(){
    if(password != cpassword){
      Swal.fire({
        'icon':'error',
         'title':'Failed',
         'text':'Password & Confirm Password do not match'

      })
    }else{

      let data = {name,email,password}
      let result = await fetch(process.env.REACT_APP_BASE_URL+'api/register',{
          method:'POST',
          headers:{
              'Content-Type':'application/json',
              'Accept':'application/json'
          },
          body:JSON.stringify(data)
      })
      result = await result.json();
      // save in local storage
      localStorage.setItem('user-info',JSON.stringify(result));
      ( async ()=>{
        await Swal.fire(
            'Great',
            'You Have Registered Successfully',
            'success'
        )
        window.location.reload()
        navigator('/')                
    })()
    }
  } 


  return (
    <>
     {/* BANNER */}
    <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Register</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Register</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="login-form pt-5 pb-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Username" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter Your Email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control type="password" value={cpassword} onChange={(e)=>{setcPassword(e.target.value)}} placeholder="Confirm Password" />
                </Form.Group>
                <Button
                  variant="link"
                  className="blue__btn btn-style round-btn"
                  onClick={signUp}
                >
                  Create Account
                </Button>
              </Form>
              <div className="or-option">
                <span>-- OR --</span>
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
