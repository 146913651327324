import React,{useEffect, useState} from 'react';
import { FormLabel } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'; 
import Swal from 'sweetalert2';

function Updateproduct(props){
    const { id } = useParams(); 

    const [name,setName] = useState('');
    const [description,setDescription] = useState('');
    const [price,setPrice] = useState('');
    const [image,setImage] = useState('');


    // fetching product data
    useEffect(()=>{
        ( async()=>{
            let result = await fetch(process.env.REACT_APP_BASE_URL+'api/product-details/'+id)
            result = await result.json();
            if(result.status == '200'){
                setName(result.data.name)
                setDescription(result.data.description)
                setPrice (result.data.calculated_price)
                setImage(result.data.images)
            }else{
                console.warn('data not found')
            }   
            })()
    },[])

    const navigator = useNavigate()
    const formdata = new  FormData()
    async function updateProduct(){
        formdata.append('name',name);
        formdata.append('description',description);
        formdata.append('price',price);
        if(image != ''){
            formdata.append('image',image);
        }
        
        
       let result = await fetch(process.env.REACT_APP_BASE_URL+'api/update-product/'+id,{
                    method:'POST',
                    body: formdata
                    })

            result = await result.json();
            if(result.status == 200){
                Swal.fire('Product Updated Successfully')
                navigator('/admin/all-products')
            }else{
                Swal.fire({
                    icon:'error',
                    title:'Failed',
                    text:'Failed To Updated product'
                })
            }
            
            
    }
    return(
        <>
        <div className='main_form'>
        <div className='col-sm-6 formDesign2'>
        <h1 className='my-5 text-center'>Update Product</h1>
        <FormLabel>Name</FormLabel>
        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className='form-control' placeholder='Name' id="namae"/><br/>
        <FormLabel>Description</FormLabel>
        <input type="text" value={description} onChange={(e)=>{setDescription(e.target.value)}} className='form-control product-description' placeholder='Description' /><br/>
        <FormLabel>Price</FormLabel>
        <input type="number" value={price} onChange={(e)=>{setPrice(e.target.value)}} className='form-control' placeholder='Price' /><br/>
        <FormLabel>Image</FormLabel>
            <div className='update-Img'>
            <input type="file" onChange={(e)=>{setImage(e.target.files[0])}} className='form-control' placeholder='Image' /><br/>
            <img src={  ((''+(image).split(",")[1]+'').includes('|'))? ( ( ( (image).split(",")[1] ).split('|')[0] ).replace(" Product Image URL: ",'') ).trim()  : ((''+(image).split(",")[1]+'').replace(' Product Image URL: ','')).trim() }/>
            </div>
        <button onClick={updateProduct} className='btn btn-primary'>Update</button>
        </div>
        </div>

        </>
    );
}

export default  Updateproduct