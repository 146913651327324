import React,{useEffect,useState} from "react"
import { Link,useNavigate } from "react-router-dom";
import { Container, Table } from "react-bootstrap";
import { wait } from "@testing-library/user-event/dist/utils";
import Userdashboardsidebar from "components/Userdashboardsidebar";


export default function Userorders(){
  const navigator = useNavigate();
  useEffect(()=>{
    if(localStorage.getItem('user-info') == null ){
        navigator('/')
    }
},[])

  
const [ordersData, setOrdersData] = useState([]);
const [productsData, setProductsData] = useState([]);
const [UsersData, setUsersData] = useState([]);
const [allOrderItems,setAllOrderItems] = useState([]);


  //   feth all orders
  useEffect(() => {
    (async () => {
      try {
        let userInfo = JSON.parse(localStorage.getItem('user-info'));
        let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-orders/'+userInfo.id);
        result = await result.json();
        setOrdersData(result);
        console.log("user orders data loaded successfully");
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);


  //feth all products
    useEffect(() => {
      (async () => {
        try {
          let result = await fetch(process.env.REACT_APP_BASE_URL+'api/show-products');
          result = await result.json();
          setProductsData(result);
          console.log("data loaded successfully");
        } catch (error) {
          console.error(error);
        }
      })();
    }, []);

  
  

    let orderId=0;
    let total = 0;    
    
   

    return (
        <>
        <div class="userdashboard-main container">
        <Userdashboardsidebar currentTab="orders"/>
        
        <div className="home-content">
        <h2 className="">User All Orders</h2>
        
      
        <div class="sales-boxes">
          <div class="recent-sales box">
            <div class="title">Recent Orders</div>
            
              <Table className="col-md-12" striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Order Date</th>
          <th>Name</th>
          <th>Email</th>
          {/* <th>Total</th> */}
          {/* <th>status</th> */}
          <th>Action</th>
          {/* <th>Edit</th> */}
        </tr>
      </thead>
      <tbody>
     {
     ordersData.map((item,index)=>
        
        <tr>

          <td>{index+1}</td>
          <td>{(item.created_at).split('T')[0]}</td>
          <td>{item.first_name+' '+item.last_name}</td>
          <td>{item.email}</td>
          {/* <td>{item.price}</td> */}
          {/* <td> */}
            {/* <img src={process.env.REACT_APP_BASE_URL+'products/'+item.image_path} className="product-img"/> */}
         {/* </td> */}
         {/* <td><button onClick={()=>{deleteProd(item.id) }} className="btn btn-danger">Delete</button></td> */}
         <td>
            <Link to={'/user/invoice-show/'+item.id}>
                <span className="btn btn-primary">View</span>
            </Link>
        </td>
        </tr>
        )
    }
        
      </tbody>
            </Table>
            
            <div class="button">
              <Link to="/admin/all-orders">See All</Link>
            </div>
          </div>
         
        </div>
        </div>
        </div>
        </>
    )

}