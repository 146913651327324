import React from "react";
import { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  Container,
  Breadcrumb,
  Row,
  Col,
  Form,
  Button,
  Nav,
  Tab,
} from "react-bootstrap";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link,json,useNavigate,useParams,useLocation } from "react-router-dom";
 
import featuredImageone from "assets/hard.png";
import featuredImagetwo from "assets/gb.png";
import featuredImagethree from "assets/cpu.png";
import slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UpdateCart from "pages/UpdateCart";
import featuredImage from "assets/cd.png";
import Swal from "sweetalert2";

export default function ProductDetail() {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  const {id} = useParams();

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);


  const [name,setName] = useState('');
  const [description,setDescription] = useState('');
  const [brand,setBrand] = useState('');
  const [price,setPrice] = useState('');
  const [image,setImage] = useState('');
  const [categoryDetails,setCatDetails] = useState('');
  
  const[otherImages,setotherImages] = useState([]);

  const [click,setClick] = useState(1);
  const [quantity,setQuantity] = useState(1);

  const navigator = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('searched');
  // alert(searchValue);


  // fetching product data
  useEffect(()=>{
      ( async()=>{
        let result;
          if(searchValue){
             result = await fetch(process.env.REACT_APP_BASE_URL+'api/searched-product-details?searched='+searchValue)
          }else{
             result = await fetch(process.env.REACT_APP_BASE_URL+'api/product-details/'+id)
          }

          result = await result.json();
          if(result.status == '200'){
            console.log('productdata'+result.data)
              setName(result.data.name)
              setDescription(result.data.description)
              setBrand (result.data.brand)
              setPrice (result.data.calculated_price)
              setCatDetails (result.data.category_details)
              setImage(( ( ( (result.data.images).split(",")[1] ).split('|')[0] ).replace('Product Image URL: ','') ).trim() )

              let images = ((result.data.images).split(",")[1] ).split('|');
              let updatedImages = [];
              let i = 0;
              images.forEach(element => {
                updatedImages.push(
                  // ((element).replace('Product Image URL: ','') ).trim()
                  ((images)[i].replace('Product Image URL: ','')).trim()
                  )
                // console.log(updatedImages)
                i++;
              });
              setotherImages(updatedImages)
              console.log(otherImages)
          }else{
              console.warn('data not found')
          }   
          })()
  },[])
  
  
function AddToCart(){
  let count;
  let oldCart = JSON.parse(localStorage.getItem('cart')) ?? [];
  let cart = {
    'product_id': id,
    'product_qty': quantity,
    'product_name': name,
    'product_price': price,
    'product_image': image,
  };

  oldCart.push(cart);
  localStorage.setItem('cart', JSON.stringify(oldCart));

  setClick(click + 1);

  const updatedCart = <UpdateCart count={click} />;
  localStorage.setItem('cart_count', updatedCart.props.count);

  setTimeout(() => {
    (async () => {
      await Swal.fire('Product Added To Cart');
      navigator('/cart')
      window.location.reload();
    })();
  }, 500);


  
}

  return (
    <>
      {/* BANNER */}
      <div className="in-page-banner">
        <Container>
          <h1 className="text-center">Product Detail</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Product Detail</li>
          </ol>
        </Container>
      </div>
      {/* END BANNER */}

      <div className="in-page-container pt-4 pb-4">
        <div className="product__detail__top">
          <Container>
            <Row>
              <Col md={6}>
                <div className="product__section__img">
                  <div className="thumbnail__image">
                    <Slider asNavFor={nav2} ref={slider1Ref}>
                    {otherImages.map((url)=>
                      <div className="product__image feaut-img product-details">
                        <img src={url} alt="" />
                      </div>                                            
                    )}


                      {/* <div className="product__image feaut-img">
                        <img src={featuredImageone} alt="" />
                      </div>

                      <div className="product__image feaut-img">
                        <img src={featuredImagetwo} alt="" />
                      </div> */}

                    </Slider>
                  </div>

                  <div className="small__images">
                    <Slider
                      asNavFor={nav1}
                      ref={slider2Ref}
                      slidesToShow={3}
                      swipeToSlide={true}
                      focusOnSelect={true}
                    >
                      {otherImages.map((url)=>
                        <div className="small__images__bottom">
                          <img src={url} alt="" />
                        </div>
                      )}

                      {/* <div className="small__images__bottom">
                        <img src={featuredImageone} alt="" />
                      </div>

                      <div className="small__images__bottom">
                        <img src={featuredImagetwo} alt="" />
                      </div> */}

                    </Slider>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="product__Section__text">
                  <h2>{name}</h2>
                  {/* <strong>Ipsum</strong> */}
                  {/* <div className="rating__pro__section">
                    <ul class="rating__pro">
                      <FontAwesomeIcon icon={solid("star")} />
                      <FontAwesomeIcon icon={solid("star")} />

                      <FontAwesomeIcon icon={solid("star")} />
                      <FontAwesomeIcon icon={solid("star")} />
                      <FontAwesomeIcon icon={solid("star")} />
                    </ul>
                    <div className="anchor">
                      <a href="#">2 reviews</a>
                    </div>
                  </div> */}
                  <div className="pt-2">
                      <p><b>Brand: </b>{brand}</p>
                  </div>
                  <div className="type">
                    <b>Category Details: </b>{categoryDetails}<br></br>
                  </div>
                  <div className="pt-2">
                      <p><b>Price: </b><span className="prodDetails-price">${price}</span></p>
                    </div>
                  <div className="pt-2">
                      <p><b>Description: </b>{description}</p>
                  </div>

                  <div className="select__options__pro">
                    {/* <Form.Select aria-label="Default select example">
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select> */}
                    <div>
                    <div className="quan-form">
                             <Button onClick={()=>
                              (quantity>0)? setQuantity(quantity-1) : 0
                              } className="dec-btn">
                               <FontAwesomeIcon icon={solid("minus")} />
                             </Button>
                             <Form.Group controlId="formBasicEmail">
                               <Form.Control type="text" value={quantity}/>
                             </Form.Group>
                             <Button onClick={()=>{setQuantity(quantity+1)}} className="inc-btn">
                               <FontAwesomeIcon icon={solid("plus")} />
                             </Button>
                           </div>
                           </div>

                    <div className="main__slect__btn">
                      
                      {/* <Link to="/cart" class="blue__btn" onClick={AddToCart}>
                        Add To Cart
                      </Link> */}
                                            
                      <button class="blue__btn" onClick={AddToCart}>
                        Add To Cart
                      </button>
                    </div>
                    <div className="btn__buy">
                     
                      <Link to="/checkout" class="blue__btn">
                        Buy it Now
                        </Link>
                      
                    </div>

                    {/* <div className="detail__table">
                      <table className="table">
                        <tbody>
                          <tr>
                            <th>Lorem:</th>
                            <td>Ipsum</td>
                          </tr>
                          
                        </tbody>
                      </table>
                    </div> */}
                  </div>

                  {/* <div className="product__social__icons">
                    <strong>Share</strong>
                    <div className="main__product__icons">
                      <ul>
                        <li>
                          <a href="#">
                            {" "}
                            <FontAwesomeIcon icon={brands("facebook-f")} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={brands("instagram")} />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <FontAwesomeIcon icon={brands("twitter")} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="description__Section">
        <Container>
          <Row>
            <Col md={12}>
              <Tab.Container defaultActiveKey="/home">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="/home">Description</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
              <Nav.Link eventKey="link-1">Option 2</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item> */}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="/home">
                    <p>
                     {description}
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="link-1">
                    <p>
                      This is the content for "Option 2" tab. You can add any
                      relevant information here.
                    </p>
                  </Tab.Pane>
                  {/* No content can be added for the disabled tab */}
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
